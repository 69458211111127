"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Children = void 0;
var react_1 = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var __1 = require("../..");
var styles = styled_components_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n\n  margin: 2em auto 4em auto;\n\n  h1 {\n    font-size: 40px;\n    font-weight: 300;\n    color: #000000;\n  }\n  @media only screen and (max-width: 1100px) {\n    padding: 0 20px;\n    min-width: 100%;\n  }\n"], ["\n  ",
    "\n\n  margin: 2em auto 4em auto;\n\n  h1 {\n    font-size: 40px;\n    font-weight: 300;\n    color: #000000;\n  }\n  @media only screen and (max-width: 1100px) {\n    padding: 0 20px;\n    min-width: 100%;\n  }\n"])), function (_a) {
    var size = _a.size;
    if (size === 'lg')
        return styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        max-width: 980px;\n      "], ["\n        max-width: 980px;\n      "])));
    if (size === 'xl')
        return styled_components_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        max-width: 1200px;\n      "], ["\n        max-width: 1200px;\n      "])));
    return styled_components_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      max-width: 780px;\n    "], ["\n      max-width: 780px;\n    "])));
});
var Actions = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 0.5em;\n"], ["\n  margin-bottom: 0.5em;\n"])));
exports.Children = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var TransparentContainer = function (_a) {
    var _b;
    var title = _a.title, className = _a.className, children = _a.children, actions = _a.actions, linkTo = _a.linkTo, subtitle = _a.subtitle;
    var header = document.getElementById('headerWrap');
    var _c = react_1.useState(120), headerHeight = _c[0], setHeaderHeight = _c[1];
    var _d = react_1.useState({
        dynamicWidth: window.innerWidth,
    }), screenSize = _d[0], getDimension = _d[1];
    var setDimension = function () {
        getDimension({
            dynamicWidth: window.innerWidth,
        });
    };
    react_1.useEffect(function () {
        window.addEventListener('resize', setDimension);
        return function () {
            window.removeEventListener('resize', setDimension);
        };
    }, [screenSize]);
    react_1.useEffect(function () {
        if (header === null || header === void 0 ? void 0 : header.clientHeight) {
            setHeaderHeight(header.clientHeight + 40);
        }
    }, [header, screenSize]);
    var getMarginTop = function () {
        if (screenSize.dynamicWidth < 1101)
            return headerHeight;
        return '2em';
    };
    return (react_1.default.createElement("div", { className: className, style: { marginTop: getMarginTop() } },
        linkTo && react_1.default.createElement(__1.Link, { to: linkTo }, "\u226A Zp\u011Bt"),
        title && react_1.default.createElement("h1", { style: (subtitle === null || subtitle === void 0 ? void 0 : subtitle.text) ? { marginBottom: '0' } : undefined }, title),
        (subtitle === null || subtitle === void 0 ? void 0 : subtitle.text) && react_1.default.createElement("span", { style: { color: (_b = subtitle.color) !== null && _b !== void 0 ? _b : '#000000', fontSize: '1em', paddingLeft: '0.5em' } }, subtitle.text),
        actions && react_1.default.createElement(Actions, null, actions),
        react_1.default.createElement(exports.Children, null, children)));
};
TransparentContainer.defaultProps = {
    actions: undefined,
    className: undefined,
    title: undefined,
    size: 'md',
    linkTo: undefined,
    subtitle: undefined,
};
exports.default = styled_components_1.default(TransparentContainer)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), styles);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
