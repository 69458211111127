"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalStyle = void 0;
var styled_components_1 = require("styled-components");
exports.GlobalStyle = styled_components_1.createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  html, body, #root {\n    height: 100%;\n  }  \n  \n  body {\n    padding: 0;\n    margin: 0;\n    background-color: #f5f5f5;\n    color: #333333;\n  }\n\n  * {\n    font-family: Roboto, sans-serif;\n    -webkit-font-smoothing: antialiased;\n  }\n\n  *,\n  *::before,\n  *::after {\n    box-sizing: border-box;\n  }\n"], ["\n  html, body, #root {\n    height: 100%;\n  }  \n  \n  body {\n    padding: 0;\n    margin: 0;\n    background-color: #f5f5f5;\n    color: #333333;\n  }\n\n  * {\n    font-family: Roboto, sans-serif;\n    -webkit-font-smoothing: antialiased;\n  }\n\n  *,\n  *::before,\n  *::after {\n    box-sizing: border-box;\n  }\n"])));
var templateObject_1;
