"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAddressLabel = void 0;
var getAddressLabel = function (value) {
    if (value == null) {
        return '';
    }
    if (value.formatovanyText && value.formatovanyText !== '') {
        return value.formatovanyText;
    }
    if (value.adresaText && value.adresaText !== '') {
        return value.adresaText;
    }
    var popisne = "" + (value.cisloPopisne || '');
    if (popisne !== '')
        popisne = " " + popisne;
    var orientacni = "" + (value.cisloOrientacni || '') + (value.cisloOrientacniZnak || '');
    if (orientacni !== '' && popisne !== '')
        orientacni = "/" + orientacni;
    if (orientacni !== '' && popisne === '')
        orientacni = " " + orientacni;
    var castObce = "" + (value.castObce || '');
    if (castObce !== '')
        castObce = ", " + castObce;
    if (value.castObce === value.obec)
        castObce = '';
    var ulice = "" + (value.ulice || '');
    if (value.cisloEvidencni)
        return (value.ulice || '') + " \u010D.ev. " + value.cisloEvidencni + castObce + ", " + (value.psc || '') + " " + (value.obec || '');
    if (ulice !== '')
        return "" + (value.ulice || '') + popisne + orientacni + castObce + ", " + (value.psc || '') + " " + (value.obec || '');
    if (value.castObce !== value.obec)
        return "" + (value.castObce || '') + popisne + ", " + (value.psc || '') + " " + (value.obec || '');
    if (value.castObce === value.obec)
        return "\u010D.p. " + popisne + ", " + (value.psc || '') + " " + (value.obec || '');
    return '';
};
exports.getAddressLabel = getAddressLabel;
