import { Button, ConfigProvider, Header, isInRole, Role, useBoolean, User, useToken } from 'common';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  z-index: 80;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
`;

const MobileMenu = styled.nav`
  background-color: #2362a2;
  height: 100vh;
  display: block;
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  width: 22.5rem;
  padding-top: 7.5rem;
  padding-bottom: 1.25rem;

  ul {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    background-color: #2362a2;
    column-gap: 7em;
    margin-top: 0;
    margin-bottom: 0;
    padding-inline-start: 0;
    padding: 0;
    li:last-child {
      margin-bottom: 25px;
    }
    li:last-child::after {
      content: '';
      display: block;
      position: absolute;
      margin: 15px 0 15px -15px;
      width: 100%;
      border-bottom: 1px solid #3077b7;
    }
    a {
      padding: 0;
    }
  }
  li {
    list-style: none;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
    padding: 15px 48px 15px 36px;
    @media only screen and (max-width: 325px) {
      text-align: initial;
    }
    a:not(.selected):hover {
      position: relative;
      color: #ebebeb;
      text-decoration: underline;
      transition: 0.3s;
      &:hover::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
      }
    }
    a.selected::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
    }
    a.selected:hover {
      color: #ebebeb;
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: 700px) {
    width: 70%;
  }
  @media only screen and (max-width: 450px) {
    width: 80%;
  }
  @media only screen and (max-width: 380px) {
    width: 100%;
  }
`;

const StyledLogo = styled.img`
  position: absolute;
  top: 0;
  margin: 11px 0 0 20px;
`;

const StyledLoginButton = styled(Button)`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 14px 30px 10px 30px;
  margin-left: 20px;
  font-style: normal;
  transition: 0.2s;
  &:hover {
    color: #2362a2;
  }
`;

type IProps = {
  jumpToContent: () => void;
  mainMenuRef: React.RefObject<HTMLDivElement>;
};

const MainMenu = ({ jumpToContent, mainMenuRef }: IProps): JSX.Element => {
  const token = useToken();
  const { roleIds, roleNames, name, surname, mandat, entityName, entityType, entityId } = token?.getDataFromToken() || {};
  const [role, setRole] = useState<string>();
  const isMobileMode = useBoolean(false);
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', setDimension);
    if (screenSize.dynamicWidth < 1101) {
      isMobileMode.setTrue();
      setIsShowMobileMenu(false);
    } else {
      isMobileMode.setFalse();
      setIsShowMobileMenu(false);
    }

    return () => {
      window.removeEventListener('resize', setDimension);
    };
  }, [screenSize]);

  if (token.getDataFromToken() && role !== token.getDataFromToken().roleIds) {
    setRole(token.getDataFromToken().roleIds);
  }

  const login = async () => {
    token.redirectLogin();
    setIsShowMobileMenu(false);
  };

  const logout = async () => {
    token.logout();
    setIsShowMobileMenu(false);
  };

  return (
    <>
      <ConfigProvider typ="znalec">
        <div ref={mainMenuRef}>
          <Header jumpToContent={jumpToContent} isPubWeb={true} isMobileMode={isMobileMode.value} isShowMobileMenu={isShowMobileMenu} setIsShowMobileMenu={setIsShowMobileMenu} />
        </div>
      </ConfigProvider>
      {isShowMobileMenu && (
        <Overlay>
          <MobileMenu role="navigation" id="nav">
            {isMobileMode && <StyledLogo src="/logo.svg" alt="logo" />}
            {token.value && (
              <User
                name={`${name} ${surname}`}
                entityName={entityName}
                userMandat={mandat}
                onLogoutClick={logout}
                entityType={entityType}
                entityId={entityId}
                isMobileMode={isMobileMode?.value}
                roleNames={isInRole([Role.Editor, Role.Spravce, Role.nahlizejici], roleIds) ? roleNames : undefined}
              />
            )}
            {!token.value && (
              <StyledLoginButton type="button" primary={false} onClick={login} name="Prihlasit">
                Přihlásit
              </StyledLoginButton>
            )}
          </MobileMenu>
        </Overlay>
      )}
    </>
  );
};

export default MainMenu;
