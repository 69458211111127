"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var query_string_1 = require("query-string");
var ramda_1 = require("ramda");
var react_1 = __importStar(require("react"));
var use_query_params_1 = require("use-query-params");
var __1 = require("../../../..");
var UkonyPosudkyUtils_1 = require("../UkonyPosudkyUtils");
var PosudkyPrehledy = function (_a) {
    var idZnalce = _a.idZnalce, zpracovano = _a.zpracovano;
    var posudkyUrl = __1.useConfig().posudkyUrl;
    var postPosudky = __1.usePostJSON(posudkyUrl + "/Prehled");
    var _b = react_1.useState(), data = _b[0], setData = _b[1];
    var loading = __1.useBoolean(false);
    var _c = react_1.useState(1), page = _c[0], setPage = _c[1];
    var _d = react_1.useState(undefined), error = _d[0], setError = _d[1];
    var _e = react_1.useState([]), navPages = _e[0], setNavPages = _e[1];
    var stranekCelkem = (data === null || data === void 0 ? void 0 : data.strankovani.stranekCelkem) || 0;
    var backQuery = use_query_params_1.useQueryParam('query', use_query_params_1.JsonParam)[0];
    var query = {
        idSubjekt: idZnalce,
        zpracovano: zpracovano,
        strankovani: {
            pocetNaStranku: 5,
        },
    };
    var filterPages = function () {
        var arr = [1];
        if (page > 3)
            arr.push(0);
        if (page > 2)
            arr.push(page - 1);
        if (page !== 1)
            arr.push(page);
        if (page < stranekCelkem)
            arr.push(page + 1);
        if (page + 2 < stranekCelkem)
            arr.push(-1);
        if (page !== stranekCelkem && page < stranekCelkem - 1)
            arr.push(stranekCelkem);
        setNavPages(arr);
    };
    var loadPage = function (newPage) { return __awaiter(void 0, void 0, void 0, function () {
        var strankaLens, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(newPage !== page)) return [3 /*break*/, 2];
                    loading.setTrue();
                    strankaLens = ramda_1.lensPath(['strankovani', 'stranka']);
                    return [4 /*yield*/, postPosudky(ramda_1.set(strankaLens, newPage, query))];
                case 1:
                    response = _a.sent();
                    if (__1.isRight(response)) {
                        setPage(newPage);
                        setData(response.value);
                    }
                    loading.setFalse();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    react_1.useEffect(function () {
        var load = function () { return __awaiter(void 0, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.setTrue();
                        return [4 /*yield*/, postPosudky(query)];
                    case 1:
                        result = _a.sent();
                        if (__1.isRight(result)) {
                            setData(result.value);
                        }
                        else
                            setError(result.value.message);
                        loading.setFalse();
                        return [2 /*return*/];
                }
            });
        }); };
        load();
    }, []);
    react_1.useEffect(function () {
        filterPages();
    }, [data === null || data === void 0 ? void 0 : data.strankovani]);
    if (!data && loading.value) {
        return react_1.default.createElement("div", { style: { margin: '1rem 0 0 0' } }, "na\u010D\u00EDt\u00E1n\u00ED");
    }
    if (error || !data)
        return react_1.default.createElement("div", { style: { margin: '1rem 0 0 0' } }, "B\u011Bhem na\u010D\u00EDt\u00E1n\u00ED nastala chyba");
    var getLinkBack = function (idPosudek) {
        var encodedQuery = use_query_params_1.encodeQueryParams({ query: use_query_params_1.JsonParam, backId: use_query_params_1.JsonParam }, { query: backQuery, backId: idZnalce });
        return "/evidence-posudku/detail/" + idPosudek + "?" + query_string_1.stringify(encodedQuery);
    };
    return react_1.default.createElement(react_1.default.Fragment, null, data.zaznamy.length > 0 ? UkonyPosudkyUtils_1.PrehledyTable(data, page, getLinkBack, loadPage, navPages) : react_1.default.createElement(UkonyPosudkyUtils_1.Negativni, null, "Nebyly nalezeny \u017E\u00E1dn\u00E9 posudky."));
};
exports.default = PosudkyPrehledy;
