import { ErrorPage, Layout, Loader, NapovedaPage, OpenDataPage, OsobniUdajePage, PristupnostPage } from 'common';
import React, { Suspense, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainMenu from './components/MainMenu';

const Home = React.lazy(() => import('./Home'));
const Znalec = React.lazy(() => import('./znalec/Znalec'));
const Tlumocnik = React.lazy(() => import('./tlumocnik/Tlumocnik'));

function App(): JSX.Element {
  const mainContentRef = useRef<HTMLDivElement>(null);
  const mainMenuRef = useRef<HTMLDivElement>(null);

  const jumpToContent = () => {
    if (mainContentRef.current) mainContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  const jumpToMenu = () => {
    if (mainMenuRef.current) mainMenuRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  return (
    <Layout jumpToMenu={jumpToMenu}>
      <MainMenu jumpToContent={jumpToContent} mainMenuRef={mainMenuRef} />
      <div role="main" id="main" ref={mainContentRef}>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact={true} path="/healthcheck">
              Status:OK
            </Route>
            <Route exact={true} path="/" component={Home} />
            <Route path="/znalec" component={Znalec} />
            <Route path="/tlumocnik" component={Tlumocnik} />
            <Route path="/osobni-udaje-a-cookies" component={OsobniUdajePage} />
            <Route path="/prohlaseni-o-pristupnosti" component={PristupnostPage} />
            <Route path="/napoveda" component={NapovedaPage} />
            <Route path="/opendata" component={OpenDataPage} />
            <Route path="*" render={() => <ErrorPage status="404" />} />
          </Switch>
        </Suspense>
      </div>
    </Layout>
  );
}

export default App;
