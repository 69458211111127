"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DruhyMandatu = exports.TypyFormatu = exports.UkonyStornoDuvody = exports.PosudkyStornoDuvody = exports.StavZadosti = exports.TypyZadostiEvidence = exports.TypyZadostiSeznam = exports.ZasobnikTypyUkonu = exports.UkonyTypOsoby = exports.StavyZapisu = exports.TypyOdmen = exports.FormyOdposlechu = exports.FormyUkonu = exports.DruhyUkonu = exports.DruhyPosudku = exports.UkonyRoleOsoby = exports.PosudkyRoleOsoby = exports.PosudkyTypOsoby = exports.PravniFormaKod = exports.StavPojisteni = exports.ZpusobyZtotozneni = exports.DruhPomucky = exports.DruhUdalosti = exports.StavUkolu = exports.ZdrojUdalosti = exports.TypyUdalosti = exports.KategorieUdalosti = exports.Role = exports.SpravaCiselnikuJazyky = exports.TlumocniciTypAdresy = exports.TlumocniciDruhOsoby = exports.TypSkutecnosti = exports.ZnalciTypAdresy = exports.ZnalciDruhOsoby = void 0;
var ZnalciDruhOsoby;
(function (ZnalciDruhOsoby) {
    ZnalciDruhOsoby[ZnalciDruhOsoby["znalec"] = 1] = "znalec";
    ZnalciDruhOsoby[ZnalciDruhOsoby["znaleckaKancelar"] = 2] = "znaleckaKancelar";
    ZnalciDruhOsoby[ZnalciDruhOsoby["znaleckyUstav"] = 3] = "znaleckyUstav";
})(ZnalciDruhOsoby = exports.ZnalciDruhOsoby || (exports.ZnalciDruhOsoby = {}));
var ZnalciTypAdresy;
(function (ZnalciTypAdresy) {
    ZnalciTypAdresy[ZnalciTypAdresy["adresaTrvalehoPobytuNaUzemiCR"] = 1] = "adresaTrvalehoPobytuNaUzemiCR";
    ZnalciTypAdresy[ZnalciTypAdresy["adresaPobytuNaUzemiCR"] = 2] = "adresaPobytuNaUzemiCR";
    ZnalciTypAdresy[ZnalciTypAdresy["adresaSidla"] = 3] = "adresaSidla";
    ZnalciTypAdresy[ZnalciTypAdresy["adresaTrvalehoPobytuMimoUzemiCR"] = 4] = "adresaTrvalehoPobytuMimoUzemiCR";
    ZnalciTypAdresy[ZnalciTypAdresy["adresaPobytuMimoUzemiCR"] = 5] = "adresaPobytuMimoUzemiCR";
    ZnalciTypAdresy[ZnalciTypAdresy["kontaktniAdresa"] = 6] = "kontaktniAdresa";
    ZnalciTypAdresy[ZnalciTypAdresy["adresaSidlaPridruzenehoSubjektu"] = 7] = "adresaSidlaPridruzenehoSubjektu";
})(ZnalciTypAdresy = exports.ZnalciTypAdresy || (exports.ZnalciTypAdresy = {}));
var TypSkutecnosti;
(function (TypSkutecnosti) {
    TypSkutecnosti[TypSkutecnosti["vytka"] = 1] = "vytka";
    TypSkutecnosti[TypSkutecnosti["spravniRizeni"] = 2] = "spravniRizeni";
    TypSkutecnosti[TypSkutecnosti["jina"] = 3] = "jina";
})(TypSkutecnosti = exports.TypSkutecnosti || (exports.TypSkutecnosti = {}));
var TlumocniciDruhOsoby;
(function (TlumocniciDruhOsoby) {
    TlumocniciDruhOsoby[TlumocniciDruhOsoby["tlumocnik"] = 1] = "tlumocnik";
    TlumocniciDruhOsoby[TlumocniciDruhOsoby["prekladatel"] = 2] = "prekladatel";
})(TlumocniciDruhOsoby = exports.TlumocniciDruhOsoby || (exports.TlumocniciDruhOsoby = {}));
var TlumocniciTypAdresy;
(function (TlumocniciTypAdresy) {
    TlumocniciTypAdresy[TlumocniciTypAdresy["adresaTrvalehoPobytuNaUzemiCR"] = 1] = "adresaTrvalehoPobytuNaUzemiCR";
    TlumocniciTypAdresy[TlumocniciTypAdresy["adresaPobytuNaUzemiCR"] = 2] = "adresaPobytuNaUzemiCR";
    TlumocniciTypAdresy[TlumocniciTypAdresy["adresaSidla"] = 3] = "adresaSidla";
    TlumocniciTypAdresy[TlumocniciTypAdresy["adresaTrvalehoPobytuMimoUzemiCR"] = 4] = "adresaTrvalehoPobytuMimoUzemiCR";
    TlumocniciTypAdresy[TlumocniciTypAdresy["adresaPobytuMimoUzemiCR"] = 5] = "adresaPobytuMimoUzemiCR";
    TlumocniciTypAdresy[TlumocniciTypAdresy["kontaktniAdresa"] = 6] = "kontaktniAdresa";
    TlumocniciTypAdresy[TlumocniciTypAdresy["kontaktniAdresaNaZadost"] = 7] = "kontaktniAdresaNaZadost";
    TlumocniciTypAdresy[TlumocniciTypAdresy["adresaSidlaZahranicni"] = 8] = "adresaSidlaZahranicni";
})(TlumocniciTypAdresy = exports.TlumocniciTypAdresy || (exports.TlumocniciTypAdresy = {}));
var SpravaCiselnikuJazyky;
(function (SpravaCiselnikuJazyky) {
    SpravaCiselnikuJazyky[SpravaCiselnikuJazyky["Cesky"] = 65] = "Cesky";
})(SpravaCiselnikuJazyky = exports.SpravaCiselnikuJazyky || (exports.SpravaCiselnikuJazyky = {}));
var Role;
(function (Role) {
    Role["Editor"] = "1";
    Role["Spravce"] = "2";
    Role["znalec"] = "3";
    Role["tlumocnik"] = "4";
    Role["nahlizejici"] = "5";
    Role["nahlizejicinazadost"] = "6";
    Role["verejnost"] = "7";
})(Role = exports.Role || (exports.Role = {}));
var KategorieUdalosti;
(function (KategorieUdalosti) {
    KategorieUdalosti[KategorieUdalosti["Zmena"] = 1] = "Zmena";
    KategorieUdalosti[KategorieUdalosti["Kontrola"] = 2] = "Kontrola";
    KategorieUdalosti[KategorieUdalosti["Ukol"] = 3] = "Ukol";
    KategorieUdalosti[KategorieUdalosti["Pristup"] = 4] = "Pristup";
    KategorieUdalosti[KategorieUdalosti["Evidence"] = 5] = "Evidence";
    KategorieUdalosti[KategorieUdalosti["Notifikace"] = 6] = "Notifikace";
})(KategorieUdalosti = exports.KategorieUdalosti || (exports.KategorieUdalosti = {}));
var TypyUdalosti;
(function (TypyUdalosti) {
    TypyUdalosti[TypyUdalosti["ZmenaSubjektuRegistrZmena"] = 1] = "ZmenaSubjektuRegistrZmena";
    TypyUdalosti[TypyUdalosti["ZmenaZnalce"] = 2] = "ZmenaZnalce";
    TypyUdalosti[TypyUdalosti["ZmenaTlumocnika"] = 3] = "ZmenaTlumocnika";
    TypyUdalosti[TypyUdalosti["ZmenaPosudku"] = 4] = "ZmenaPosudku";
    TypyUdalosti[TypyUdalosti["ZmenaUkonu"] = 5] = "ZmenaUkonu";
    TypyUdalosti[TypyUdalosti["PristupKZaznamu"] = 6] = "PristupKZaznamu";
    TypyUdalosti[TypyUdalosti["EvidenceZnalce"] = 7] = "EvidenceZnalce";
    TypyUdalosti[TypyUdalosti["EvidenceTlumocnika"] = 8] = "EvidenceTlumocnika";
    TypyUdalosti[TypyUdalosti["EvidenceUkonu"] = 9] = "EvidenceUkonu";
    TypyUdalosti[TypyUdalosti["EvidencePosudku"] = 10] = "EvidencePosudku";
    TypyUdalosti[TypyUdalosti["EvidencePojisteni"] = 11] = "EvidencePojisteni";
    TypyUdalosti[TypyUdalosti["EvidencePomucky"] = 12] = "EvidencePomucky";
    TypyUdalosti[TypyUdalosti["EvidenceOsoby"] = 13] = "EvidenceOsoby";
    TypyUdalosti[TypyUdalosti["ZmenaSubjektuRegistrKontrola"] = 14] = "ZmenaSubjektuRegistrKontrola";
    TypyUdalosti[TypyUdalosti["UkolSchvaleniPristupNahlizejici"] = 15] = "UkolSchvaleniPristupNahlizejici";
    TypyUdalosti[TypyUdalosti["UkolSchvaleniProfesniPojisteni"] = 16] = "UkolSchvaleniProfesniPojisteni";
    TypyUdalosti[TypyUdalosti["KontrolaNevlozeniProfesnihoPojisteni"] = 17] = "KontrolaNevlozeniProfesnihoPojisteni";
    TypyUdalosti[TypyUdalosti["KontrolaTerminuUkonu"] = 18] = "KontrolaTerminuUkonu";
    TypyUdalosti[TypyUdalosti["KontrolaTerminuPosudku"] = 19] = "KontrolaTerminuPosudku";
    TypyUdalosti[TypyUdalosti["KontrolaZapisuZnalce"] = 20] = "KontrolaZapisuZnalce";
    TypyUdalosti[TypyUdalosti["KontrolaZapisuTlumocnika"] = 21] = "KontrolaZapisuTlumocnika";
    TypyUdalosti[TypyUdalosti["KontrolaPosudkuZnalce"] = 22] = "KontrolaPosudkuZnalce";
    TypyUdalosti[TypyUdalosti["KontrolaUkonuTlumocnika"] = 23] = "KontrolaUkonuTlumocnika";
    TypyUdalosti[TypyUdalosti["KontrolaUplnostiUdaju"] = 24] = "KontrolaUplnostiUdaju";
    TypyUdalosti[TypyUdalosti["UkolSchvaleniOpravnehoPojisteni"] = 25] = "UkolSchvaleniOpravnehoPojisteni";
    TypyUdalosti[TypyUdalosti["NotifikaceZbyvajiciLhutaPojisteni"] = 26] = "NotifikaceZbyvajiciLhutaPojisteni";
    TypyUdalosti[TypyUdalosti["NotifikaceZamitnutiPojisteni"] = 27] = "NotifikaceZamitnutiPojisteni";
    TypyUdalosti[TypyUdalosti["ZmenaZadost"] = 28] = "ZmenaZadost";
    TypyUdalosti[TypyUdalosti["EvidenceZadosti"] = 29] = "EvidenceZadosti";
    TypyUdalosti[TypyUdalosti["ZmenaAdministrace"] = 30] = "ZmenaAdministrace";
    TypyUdalosti[TypyUdalosti["Administrace"] = 31] = "Administrace";
    TypyUdalosti[TypyUdalosti["KontrolaPovinnostiDolozitPojisteni"] = 32] = "KontrolaPovinnostiDolozitPojisteni";
})(TypyUdalosti = exports.TypyUdalosti || (exports.TypyUdalosti = {}));
var ZdrojUdalosti;
(function (ZdrojUdalosti) {
    ZdrojUdalosti[ZdrojUdalosti["EvidenceZnalcu"] = 1] = "EvidenceZnalcu";
    ZdrojUdalosti[ZdrojUdalosti["EvidenceTlumocniku"] = 2] = "EvidenceTlumocniku";
    ZdrojUdalosti[ZdrojUdalosti["EvidencePosudku"] = 3] = "EvidencePosudku";
    ZdrojUdalosti[ZdrojUdalosti["EvidenceUkonu"] = 4] = "EvidenceUkonu";
    ZdrojUdalosti[ZdrojUdalosti["ZasobnikPrace"] = 5] = "ZasobnikPrace";
    ZdrojUdalosti[ZdrojUdalosti["EvidencePomucek"] = 6] = "EvidencePomucek";
    ZdrojUdalosti[ZdrojUdalosti["EvidencePojisteni"] = 7] = "EvidencePojisteni";
    ZdrojUdalosti[ZdrojUdalosti["EvidenceZadosti"] = 8] = "EvidenceZadosti";
})(ZdrojUdalosti = exports.ZdrojUdalosti || (exports.ZdrojUdalosti = {}));
var StavUkolu;
(function (StavUkolu) {
    StavUkolu[StavUkolu["Nedokonceno"] = 1] = "Nedokonceno";
    StavUkolu[StavUkolu["Dokonceno"] = 2] = "Dokonceno";
})(StavUkolu = exports.StavUkolu || (exports.StavUkolu = {}));
var DruhUdalosti;
(function (DruhUdalosti) {
    DruhUdalosti[DruhUdalosti["Informativni"] = 1] = "Informativni";
    DruhUdalosti[DruhUdalosti["KeKontrole"] = 2] = "KeKontrole";
})(DruhUdalosti = exports.DruhUdalosti || (exports.DruhUdalosti = {}));
var DruhPomucky;
(function (DruhPomucky) {
    DruhPomucky[DruhPomucky["PrukazTlumocnika"] = 1] = "PrukazTlumocnika";
    DruhPomucky[DruhPomucky["PrukazZnalce"] = 2] = "PrukazZnalce";
    DruhPomucky[DruhPomucky["PrukazPrekladatele"] = 3] = "PrukazPrekladatele";
})(DruhPomucky = exports.DruhPomucky || (exports.DruhPomucky = {}));
var ZpusobyZtotozneni;
(function (ZpusobyZtotozneni) {
    ZpusobyZtotozneni[ZpusobyZtotozneni["ico"] = 1] = "ico";
    ZpusobyZtotozneni[ZpusobyZtotozneni["ds"] = 2] = "ds";
    ZpusobyZtotozneni[ZpusobyZtotozneni["dalsi"] = 3] = "dalsi";
})(ZpusobyZtotozneni = exports.ZpusobyZtotozneni || (exports.ZpusobyZtotozneni = {}));
var StavPojisteni;
(function (StavPojisteni) {
    StavPojisteni[StavPojisteni["CekaNaSchvaleni"] = 1] = "CekaNaSchvaleni";
    StavPojisteni[StavPojisteni["Schvaleno"] = 2] = "Schvaleno";
    StavPojisteni[StavPojisteni["Zamitnuto"] = 3] = "Zamitnuto";
})(StavPojisteni = exports.StavPojisteni || (exports.StavPojisteni = {}));
var PravniFormaKod;
(function (PravniFormaKod) {
    PravniFormaKod[PravniFormaKod["FytickaOsoba"] = 100] = "FytickaOsoba";
    PravniFormaKod[PravniFormaKod["OrganizacniSlozkaStatu"] = 325] = "OrganizacniSlozkaStatu";
    PravniFormaKod[PravniFormaKod["Zahranicni"] = 424] = "Zahranicni";
    PravniFormaKod[PravniFormaKod["VnitrniOrganSlozkyStatu"] = 525] = "VnitrniOrganSlozkyStatu";
})(PravniFormaKod = exports.PravniFormaKod || (exports.PravniFormaKod = {}));
var PosudkyTypOsoby;
(function (PosudkyTypOsoby) {
    PosudkyTypOsoby[PosudkyTypOsoby["FyzickaOsoba"] = 1] = "FyzickaOsoba";
    PosudkyTypOsoby[PosudkyTypOsoby["FyzickaOsobaPodnikajici"] = 2] = "FyzickaOsobaPodnikajici";
    PosudkyTypOsoby[PosudkyTypOsoby["PravnickaOsoba"] = 3] = "PravnickaOsoba";
    PosudkyTypOsoby[PosudkyTypOsoby["OVM"] = 4] = "OVM";
})(PosudkyTypOsoby = exports.PosudkyTypOsoby || (exports.PosudkyTypOsoby = {}));
var PosudkyRoleOsoby;
(function (PosudkyRoleOsoby) {
    PosudkyRoleOsoby[PosudkyRoleOsoby["Zadavatel"] = 1] = "Zadavatel";
    PosudkyRoleOsoby[PosudkyRoleOsoby["Zpracovatel"] = 2] = "Zpracovatel";
    PosudkyRoleOsoby[PosudkyRoleOsoby["Znalec"] = 3] = "Znalec";
    PosudkyRoleOsoby[PosudkyRoleOsoby["ZnaleckaKancelar"] = 4] = "ZnaleckaKancelar";
    PosudkyRoleOsoby[PosudkyRoleOsoby["ZnaleckyUstav"] = 5] = "ZnaleckyUstav";
    PosudkyRoleOsoby[PosudkyRoleOsoby["OsobaPodilejiciNaZpracovani"] = 6] = "OsobaPodilejiciNaZpracovani";
    PosudkyRoleOsoby[PosudkyRoleOsoby["ZnalecPodilejiciNaZpracovani"] = 7] = "ZnalecPodilejiciNaZpracovani";
    PosudkyRoleOsoby[PosudkyRoleOsoby["ZnaleckaKancelarPodilejiciNaZpracovani"] = 8] = "ZnaleckaKancelarPodilejiciNaZpracovani";
    PosudkyRoleOsoby[PosudkyRoleOsoby["ZnaleckyUstavPodilejiciNaZpracovani"] = 9] = "ZnaleckyUstavPodilejiciNaZpracovani";
    PosudkyRoleOsoby[PosudkyRoleOsoby["Konzultant"] = 10] = "Konzultant";
    PosudkyRoleOsoby[PosudkyRoleOsoby["DalsiOsoba"] = 11] = "DalsiOsoba";
    PosudkyRoleOsoby[PosudkyRoleOsoby["OsobaZapojenaDoVedeckovyzkumneCinnosti"] = 12] = "OsobaZapojenaDoVedeckovyzkumneCinnosti";
    PosudkyRoleOsoby[PosudkyRoleOsoby["OsobaVeSluzebnimPomeru"] = 13] = "OsobaVeSluzebnimPomeru";
    PosudkyRoleOsoby[PosudkyRoleOsoby["OsobaProstrednictvimKtereKonzultantPosuzuval"] = 14] = "OsobaProstrednictvimKtereKonzultantPosuzuval";
    PosudkyRoleOsoby[PosudkyRoleOsoby["ZpracovatelZnalecVRamciZKneboZU"] = 15] = "ZpracovatelZnalecVRamciZKneboZU";
    PosudkyRoleOsoby[PosudkyRoleOsoby["ZpracovatelOsobaVedeckovyzkumnaCinnostRamciZKneboZU"] = 16] = "ZpracovatelOsobaVedeckovyzkumnaCinnostRamciZKneboZU";
    PosudkyRoleOsoby[PosudkyRoleOsoby["ZpracovatelOsobaNezbytnaOdbornostVRamciZKneboZU"] = 17] = "ZpracovatelOsobaNezbytnaOdbornostVRamciZKneboZU";
    PosudkyRoleOsoby[PosudkyRoleOsoby["Ostatni"] = 18] = "Ostatni";
})(PosudkyRoleOsoby = exports.PosudkyRoleOsoby || (exports.PosudkyRoleOsoby = {}));
var UkonyRoleOsoby;
(function (UkonyRoleOsoby) {
    UkonyRoleOsoby[UkonyRoleOsoby["Zadavatel"] = 1] = "Zadavatel";
    UkonyRoleOsoby[UkonyRoleOsoby["Zpracovatel"] = 2] = "Zpracovatel";
    UkonyRoleOsoby[UkonyRoleOsoby["Tlumocnik"] = 3] = "Tlumocnik";
    UkonyRoleOsoby[UkonyRoleOsoby["Prekladatel"] = 4] = "Prekladatel";
    UkonyRoleOsoby[UkonyRoleOsoby["OsobaPodilejiciZpracovani"] = 5] = "OsobaPodilejiciZpracovani";
    UkonyRoleOsoby[UkonyRoleOsoby["Konzultant"] = 6] = "Konzultant";
    UkonyRoleOsoby[UkonyRoleOsoby["DalsiOsoba"] = 7] = "DalsiOsoba";
    UkonyRoleOsoby[UkonyRoleOsoby["OsobaPosuzujiciZvlastniOtazky"] = 8] = "OsobaPosuzujiciZvlastniOtazky";
})(UkonyRoleOsoby = exports.UkonyRoleOsoby || (exports.UkonyRoleOsoby = {}));
var DruhyPosudku;
(function (DruhyPosudku) {
    DruhyPosudku[DruhyPosudku["Posudek"] = 1] = "Posudek";
    DruhyPosudku[DruhyPosudku["RevizniPosudek"] = 2] = "RevizniPosudek";
    DruhyPosudku[DruhyPosudku["Dodatek"] = 3] = "Dodatek";
})(DruhyPosudku = exports.DruhyPosudku || (exports.DruhyPosudku = {}));
var DruhyUkonu;
(function (DruhyUkonu) {
    DruhyUkonu[DruhyUkonu["TlumocnickyUkon"] = 1] = "TlumocnickyUkon";
    DruhyUkonu[DruhyUkonu["PrekladatelskyUkon"] = 2] = "PrekladatelskyUkon";
    DruhyUkonu[DruhyUkonu["RevizniTlumocnickyUkon"] = 3] = "RevizniTlumocnickyUkon";
    DruhyUkonu[DruhyUkonu["RevizniPrekladatelskyUkon"] = 4] = "RevizniPrekladatelskyUkon";
})(DruhyUkonu = exports.DruhyUkonu || (exports.DruhyUkonu = {}));
var FormyUkonu;
(function (FormyUkonu) {
    FormyUkonu[FormyUkonu["Ustni"] = 1] = "Ustni";
    FormyUkonu[FormyUkonu["Pisemny"] = 2] = "Pisemny";
})(FormyUkonu = exports.FormyUkonu || (exports.FormyUkonu = {}));
var FormyOdposlechu;
(function (FormyOdposlechu) {
    FormyOdposlechu[FormyOdposlechu["PrepisOdposlechu"] = 1] = "PrepisOdposlechu";
    FormyOdposlechu[FormyOdposlechu["PrekladOdposlechu"] = 2] = "PrekladOdposlechu";
})(FormyOdposlechu = exports.FormyOdposlechu || (exports.FormyOdposlechu = {}));
var TypyOdmen;
(function (TypyOdmen) {
    TypyOdmen[TypyOdmen["Smluvni"] = 1] = "Smluvni";
    TypyOdmen[TypyOdmen["Urcena"] = 2] = "Urcena";
})(TypyOdmen = exports.TypyOdmen || (exports.TypyOdmen = {}));
var StavyZapisu;
(function (StavyZapisu) {
    StavyZapisu[StavyZapisu["Zapsano"] = 1] = "Zapsano";
    StavyZapisu[StavyZapisu["Neaktualizovano"] = 2] = "Neaktualizovano";
    StavyZapisu[StavyZapisu["Nezapsano"] = 3] = "Nezapsano";
})(StavyZapisu = exports.StavyZapisu || (exports.StavyZapisu = {}));
var UkonyTypOsoby;
(function (UkonyTypOsoby) {
    UkonyTypOsoby[UkonyTypOsoby["FyzickaOsoba"] = 1] = "FyzickaOsoba";
    UkonyTypOsoby[UkonyTypOsoby["FyzickaOsobaPodnikajici"] = 2] = "FyzickaOsobaPodnikajici";
    UkonyTypOsoby[UkonyTypOsoby["PravnickaOsoba"] = 3] = "PravnickaOsoba";
    UkonyTypOsoby[UkonyTypOsoby["OVM"] = 4] = "OVM";
})(UkonyTypOsoby = exports.UkonyTypOsoby || (exports.UkonyTypOsoby = {}));
var ZasobnikTypyUkonu;
(function (ZasobnikTypyUkonu) {
    ZasobnikTypyUkonu[ZasobnikTypyUkonu["Posudek"] = 1] = "Posudek";
    ZasobnikTypyUkonu[ZasobnikTypyUkonu["RevizniPosudek"] = 2] = "RevizniPosudek";
    ZasobnikTypyUkonu[ZasobnikTypyUkonu["Dodatek"] = 3] = "Dodatek";
    ZasobnikTypyUkonu[ZasobnikTypyUkonu["Ukon"] = 4] = "Ukon";
    ZasobnikTypyUkonu[ZasobnikTypyUkonu["RevizniUkon"] = 5] = "RevizniUkon";
})(ZasobnikTypyUkonu = exports.ZasobnikTypyUkonu || (exports.ZasobnikTypyUkonu = {}));
var TypyZadostiSeznam;
(function (TypyZadostiSeznam) {
    TypyZadostiSeznam[TypyZadostiSeznam["kompletniZapis"] = 1] = "kompletniZapis";
    TypyZadostiSeznam[TypyZadostiSeznam["spravniRizeni"] = 2] = "spravniRizeni";
    TypyZadostiSeznam[TypyZadostiSeznam["jineSkutecnosti"] = 3] = "jineSkutecnosti";
    TypyZadostiSeznam[TypyZadostiSeznam["adresaPobytu"] = 4] = "adresaPobytu";
    TypyZadostiSeznam[TypyZadostiSeznam["odmitnutiTlumocnickehoUkonu"] = 5] = "odmitnutiTlumocnickehoUkonu";
    TypyZadostiSeznam[TypyZadostiSeznam["prestupky"] = 6] = "prestupky";
    TypyZadostiSeznam[TypyZadostiSeznam["vytky"] = 7] = "vytky";
})(TypyZadostiSeznam = exports.TypyZadostiSeznam || (exports.TypyZadostiSeznam = {}));
var TypyZadostiEvidence;
(function (TypyZadostiEvidence) {
    TypyZadostiEvidence[TypyZadostiEvidence["kompletniEvidence"] = 1] = "kompletniEvidence";
    TypyZadostiEvidence[TypyZadostiEvidence["vypisUkonu"] = 2] = "vypisUkonu";
    TypyZadostiEvidence[TypyZadostiEvidence["detailUkonu"] = 3] = "detailUkonu";
    TypyZadostiEvidence[TypyZadostiEvidence["udajeOPoctechPosudku"] = 4] = "udajeOPoctechPosudku";
    TypyZadostiEvidence[TypyZadostiEvidence["detailUkonuVcetneZnalecnehoOdmen"] = 5] = "detailUkonuVcetneZnalecnehoOdmen";
})(TypyZadostiEvidence = exports.TypyZadostiEvidence || (exports.TypyZadostiEvidence = {}));
var StavZadosti;
(function (StavZadosti) {
    StavZadosti[StavZadosti["CekaNaSchvaleni"] = 1] = "CekaNaSchvaleni";
    StavZadosti[StavZadosti["Schvaleno"] = 2] = "Schvaleno";
    StavZadosti[StavZadosti["Zamitnuto"] = 3] = "Zamitnuto";
})(StavZadosti = exports.StavZadosti || (exports.StavZadosti = {}));
var PosudkyStornoDuvody;
(function (PosudkyStornoDuvody) {
    PosudkyStornoDuvody[PosudkyStornoDuvody["NadmerneVytizeniZnalce"] = 1] = "NadmerneVytizeniZnalce";
    PosudkyStornoDuvody[PosudkyStornoDuvody["PodjatostZnalce"] = 2] = "PodjatostZnalce";
    PosudkyStornoDuvody[PosudkyStornoDuvody["ProdleniZnalce"] = 3] = "ProdleniZnalce";
    PosudkyStornoDuvody[PosudkyStornoDuvody["NeprilehaveOpravneniZnalce"] = 4] = "NeprilehaveOpravneniZnalce";
    PosudkyStornoDuvody[PosudkyStornoDuvody["JinyDuvodZnalce"] = 5] = "JinyDuvodZnalce";
    PosudkyStornoDuvody[PosudkyStornoDuvody["ZruseniZakazkyZadavatelem"] = 6] = "ZruseniZakazkyZadavatelem";
    PosudkyStornoDuvody[PosudkyStornoDuvody["Jiny"] = 7] = "Jiny";
})(PosudkyStornoDuvody = exports.PosudkyStornoDuvody || (exports.PosudkyStornoDuvody = {}));
var UkonyStornoDuvody;
(function (UkonyStornoDuvody) {
    UkonyStornoDuvody[UkonyStornoDuvody["NadmerneVytizeniTlumocnika"] = 1] = "NadmerneVytizeniTlumocnika";
    UkonyStornoDuvody[UkonyStornoDuvody["PodjatostTlumocnika"] = 2] = "PodjatostTlumocnika";
    UkonyStornoDuvody[UkonyStornoDuvody["JinyDuvodTlumocnika"] = 3] = "JinyDuvodTlumocnika";
    UkonyStornoDuvody[UkonyStornoDuvody["ZruseniZakazkyZadavatelemTl"] = 4] = "ZruseniZakazkyZadavatelemTl";
    UkonyStornoDuvody[UkonyStornoDuvody["JinyTl"] = 5] = "JinyTl";
    UkonyStornoDuvody[UkonyStornoDuvody["NadmerneVytizeniPrekladatele"] = 6] = "NadmerneVytizeniPrekladatele";
    UkonyStornoDuvody[UkonyStornoDuvody["PodjatostPrekladatele"] = 7] = "PodjatostPrekladatele";
    UkonyStornoDuvody[UkonyStornoDuvody["ProdleniPrekladatele"] = 8] = "ProdleniPrekladatele";
    UkonyStornoDuvody[UkonyStornoDuvody["JinyDuvodPrekladatele"] = 9] = "JinyDuvodPrekladatele";
    UkonyStornoDuvody[UkonyStornoDuvody["ZruseniZakazkyZadavatelemPr"] = 10] = "ZruseniZakazkyZadavatelemPr";
    UkonyStornoDuvody[UkonyStornoDuvody["JinyPr"] = 11] = "JinyPr";
})(UkonyStornoDuvody = exports.UkonyStornoDuvody || (exports.UkonyStornoDuvody = {}));
var TypyFormatu;
(function (TypyFormatu) {
    TypyFormatu[TypyFormatu["CSV"] = 1] = "CSV";
    TypyFormatu[TypyFormatu["XML"] = 2] = "XML";
    TypyFormatu[TypyFormatu["XLS"] = 3] = "XLS";
    TypyFormatu[TypyFormatu["JSON"] = 4] = "JSON";
})(TypyFormatu = exports.TypyFormatu || (exports.TypyFormatu = {}));
var DruhyMandatu;
(function (DruhyMandatu) {
    DruhyMandatu[DruhyMandatu["Znalec"] = 1] = "Znalec";
    DruhyMandatu[DruhyMandatu["Tlumocnik"] = 2] = "Tlumocnik";
    DruhyMandatu[DruhyMandatu["Prekladatel"] = 3] = "Prekladatel";
    DruhyMandatu[DruhyMandatu["ZnaleckaKancelar"] = 4] = "ZnaleckaKancelar";
    DruhyMandatu[DruhyMandatu["ZnaleckyUstav"] = 5] = "ZnaleckyUstav";
    DruhyMandatu[DruhyMandatu["InterniPracovnik"] = 6] = "InterniPracovnik";
    DruhyMandatu[DruhyMandatu["ExterniUzivatel"] = 7] = "ExterniUzivatel";
    DruhyMandatu[DruhyMandatu["Nahlizejici"] = 8] = "Nahlizejici";
})(DruhyMandatu = exports.DruhyMandatu || (exports.DruhyMandatu = {}));
