"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var formik_1 = require("formik");
var react_1 = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var __1 = require("../../..");
var hooks_1 = require("../../../hooks");
var utils_1 = require("../utils");
var StyledInput = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 1px solid #686868;\n  border-radius: 4px;\n  height: 50px;\n  background-color: transparent;\n  display: flex;\n  position: relative;\n  transition: 100ms;\n  ", ";\n  ", "\n\n  ", "\n\n  ", "\n"], ["\n  border: 1px solid #686868;\n  border-radius: 4px;\n  height: 50px;\n  background-color: transparent;\n  display: flex;\n  position: relative;\n  transition: 100ms;\n  ", ";\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n"])), function (_a) {
    var $size = _a.$size;
    return utils_1.getInputSizeStyles($size);
}, function (_a) {
    var $focused = _a.$focused;
    return $focused && styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      box-shadow: 0 0 0 1px #2362a2;\n    "], ["\n      box-shadow: 0 0 0 1px #2362a2;\n    "])));
}, function (_a) {
    var $hasError = _a.$hasError;
    return $hasError && styled_components_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      border: 2px solid #ba0d23;\n    "], ["\n      border: 2px solid #ba0d23;\n    "])));
}, function (_a) {
    var $readOnly = _a.$readOnly;
    return $readOnly && styled_components_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background-color: #eff3f4;\n    "], ["\n      background-color: #eff3f4;\n    "])));
});
var PhoneCode = styled_components_1.default.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 15px;\n  padding-left: 13px;\n  border: none;\n  height: 50px;\n  width: 50px;\n  outline: none;\n  background-color: transparent;\n  @media only screen and (max-width: 500px) {\n    width: 50px !important;\n  }\n"], ["\n  font-size: 15px;\n  padding-left: 13px;\n  border: none;\n  height: 50px;\n  width: 50px;\n  outline: none;\n  background-color: transparent;\n  @media only screen and (max-width: 500px) {\n    width: 50px !important;\n  }\n"])));
var PhoneNumber = styled_components_1.default.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 15px;\n  border: none;\n  height: 50px;\n  width: 100%;\n  text-overflow: ellipsis;\n  outline: none;\n  background-color: transparent;\n\n  ::placeholder {\n    /* Chrome, Firefox, Opera, Safari 10.1+ */\n    color: #767676;\n    opacity: 1; /* Firefox */\n  }\n\n  :-ms-input-placeholder {\n    /* Internet Explorer 10-11 */\n    color: #767676;\n  }\n\n  ::-ms-input-placeholder {\n    /* Microsoft Edge */\n    color: #767676;\n  }\n"], ["\n  font-size: 15px;\n  border: none;\n  height: 50px;\n  width: 100%;\n  text-overflow: ellipsis;\n  outline: none;\n  background-color: transparent;\n\n  ::placeholder {\n    /* Chrome, Firefox, Opera, Safari 10.1+ */\n    color: #767676;\n    opacity: 1; /* Firefox */\n  }\n\n  :-ms-input-placeholder {\n    /* Internet Explorer 10-11 */\n    color: #767676;\n  }\n\n  ::-ms-input-placeholder {\n    /* Microsoft Edge */\n    color: #767676;\n  }\n"])));
var BreakLine = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 15px 3px;\n  border-radius: 1px;\n  height: 20px;\n  width: 2px;\n  background-color: #d0d6da;\n\n  ", "\n"], ["\n  margin: 15px 3px;\n  border-radius: 1px;\n  height: 20px;\n  width: 2px;\n  background-color: #d0d6da;\n\n  ",
    "\n"])), function (_a) {
    var $focused = _a.$focused;
    return $focused && styled_components_1.css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      background-color: #686868;\n    "], ["\n      background-color: #686868;\n    "])));
});
var PhoneField = function (_a) {
    var name = _a.name, hasError = _a.hasError, readOnly = _a.readOnly, onChange = _a.onChange, size = _a.size, toolTip = _a.toolTip;
    var _b = formik_1.useField(name + ".cislo"), fields = _b[0], meta = _b[1], helpers = _b[2];
    var focused = hooks_1.useBoolean(false);
    var numberEmpty = hooks_1.useBoolean(true);
    var numberLengthError = hooks_1.useBoolean(false);
    var codeLengthError = hooks_1.useBoolean(false);
    var _c = react_1.useState(), numberValue = _c[0], setNumberValue = _c[1];
    var _d = react_1.useState(), codeValue = _d[0], setCodeValue = _d[1];
    var removeSymbols = function (input) {
        return input.replace(/[^0-9]/g, '');
    };
    react_1.useEffect(function () {
        if (fields.value) {
            var inputFiltered = removeSymbols(fields.value);
            var parts = inputFiltered.split(/(.{3})/).filter(function (i) { return i; });
            var lastPart = parts[(parts === null || parts === void 0 ? void 0 : parts.length) - 1];
            var output = parts.join(' ');
            if ((lastPart === null || lastPart === void 0 ? void 0 : lastPart.length) === 1 && !numberLengthError.value) {
                output = "" + output.substring(0, output.lastIndexOf(' ')) + lastPart;
            }
            helpers.setValue(output);
        }
    }, [fields.value]);
    var getInitCode = function () {
        var field = formik_1.useField(name + ".kod")[0];
        return field.value || undefined;
    };
    var getInitNumber = function () {
        var field = formik_1.useField(name + ".cislo")[0];
        return field.value || undefined;
    };
    var initCode = getInitCode();
    var initNumber = getInitNumber();
    var codeFormat = function (e) {
        var inputFiltered = removeSymbols(e.target.value);
        codeLengthError.setValue(inputFiltered.length === 0);
        var output = "+" + inputFiltered;
        setCodeValue(output);
        onChange(e);
    };
    var numberFormat = function (e) {
        var inputFiltered = removeSymbols(e.target.value);
        numberEmpty.setValue(inputFiltered.length === 0);
        // druha cast validace je v IntWeb/znalec/soudniznalec/utils.ts
        numberLengthError.setValue(inputFiltered.length < 1);
        var parts = inputFiltered.split(/(.{3})/).filter(function (i) { return i; });
        var lastPart = parts[(parts === null || parts === void 0 ? void 0 : parts.length) - 1];
        var output = parts.join(' ');
        if ((lastPart === null || lastPart === void 0 ? void 0 : lastPart.length) === 1 && !numberLengthError.value) {
            output = "" + output.substring(0, output.lastIndexOf(' ')) + lastPart;
        }
        setNumberValue(output);
        onChange(e);
    };
    var validateFormat = function () {
        if (numberValue !== undefined && initNumber !== undefined && !numberEmpty.value && (codeLengthError.value || numberLengthError.value))
            return true;
        return false;
    };
    var getNumberValue = function () {
        if (initNumber === undefined)
            return '';
        if (numberValue === undefined)
            return initNumber;
        return numberValue;
    };
    var getCodeValue = function () {
        if (initCode === undefined && (codeValue === null || codeValue === void 0 ? void 0 : codeValue.length) !== 1)
            return '+420';
        if (codeValue === undefined)
            return initCode;
        return codeValue;
    };
    return (react_1.default.createElement(__1.Tooltip, { content: toolTip },
        react_1.default.createElement(StyledInput, { "$focused": focused.value, "$readOnly": readOnly, "$hasError": validateFormat() || hasError, "$size": size },
            react_1.default.createElement(PhoneCode, { value: getCodeValue(), name: name + ".kod", readOnly: readOnly, onFocus: function () { return focused.setTrue(); }, onBlur: function () { return focused.setFalse(); }, maxLength: 4, onChange: function (e) { return codeFormat(e); } }),
            react_1.default.createElement(BreakLine, { "$focused": focused.value }),
            react_1.default.createElement(PhoneNumber, { value: getNumberValue(), name: name + ".cislo", placeholder: "123 456 7890", readOnly: readOnly, onFocus: function () { return focused.setTrue(); }, onBlur: function () { return focused.setFalse(); }, maxLength: 244, onChange: function (e) { return numberFormat(e); }, id: name }))));
};
PhoneField.defaultProps = {
    readOnly: false,
    size: 'xl',
    toolTip: undefined,
    hasError: undefined,
};
exports.default = PhoneField;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
