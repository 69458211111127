"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useBoolean = function (initial) {
    var _a = react_1.useState(!!initial), value = _a[0], setValue = _a[1];
    return {
        value: value,
        setValue: setValue,
        setFalse: function () { return setValue(false); },
        setTrue: function () { return setValue(true); },
        toggle: function () { return setValue(!value); },
    };
};
exports.default = useBoolean;
