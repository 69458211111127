"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var icons_1 = require("@ant-design/icons");
var ramda_1 = require("ramda");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var styled_components_1 = __importStar(require("styled-components"));
var __1 = require("../..");
var utils_1 = require("../utils/utils");
var utils_2 = require("./utils");
var getTypNazev = function (typ) {
    if (typ.id === __1.TypyUdalosti.UkolSchvaleniProfesniPojisteni)
        return 'Posouzení dokumentu profesního pojištění';
    if (typ.id === __1.TypyUdalosti.UkolSchvaleniOpravnehoPojisteni)
        return 'Posouzení opravného vložení dokumentu profesního pojištění';
    return typ === null || typ === void 0 ? void 0 : typ.nazev;
};
var GreenButton = styled_components_1.default(__1.Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #1ca854;\n  border: none;\n"], ["\n  background: #1ca854;\n  border: none;\n"])));
var SubmitDiv = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  row-gap: 10px;\n  border-radius: 5px;\n  padding: 30px;\n  background-color: #eaeff3;\n"], ["\n  display: grid;\n  row-gap: 10px;\n  border-radius: 5px;\n  padding: 30px;\n  background-color: #eaeff3;\n"])));
var ZaitnutiHeader = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
var ZaitnutiBody = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  row-gap: 10px;\n"], ["\n  display: grid;\n  row-gap: 10px;\n"])));
var ButtonsDiv = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
var StavSchvaleniLabel = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: bold;\n  color: #fe505d;\n  ", "\n"], ["\n  font-weight: bold;\n  color: #fe505d;\n  ",
    "\n"])), function (_a) {
    var $schvaleno = _a.$schvaleno;
    return $schvaleno && styled_components_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      color: #1ca854;\n    "], ["\n      color: #1ca854;\n    "])));
});
var stavPojisteni = function (stav) {
    return react_1.default.createElement(StavSchvaleniLabel, { "$schvaleno": stav.id === __1.StavPojisteni.Schvaleno }, stav.nazev);
};
var stavZadosti = function (stav) {
    return react_1.default.createElement(StavSchvaleniLabel, { "$schvaleno": stav.id === __1.StavZadosti.Schvaleno }, stav.nazev);
};
var validation = function (values, zamitnuti, pojisteni, zadost) {
    var duvodLens = ramda_1.lensPath(['duvodZamitnuti']);
    var terminDolozeniLens = ramda_1.lensPath(['terminDolozeni']);
    var trvaniPristupuLens = ramda_1.lensPath(['trvaniPristupu']);
    var pipe1 = ramda_1.pipe(ramda_1.when(function () { return zamitnuti && !ramda_1.view(duvodLens, values); }, ramda_1.set(duvodLens, 'Důvod zamítnutí je povinný')), ramda_1.when(function () { return pojisteni && !ramda_1.view(terminDolozeniLens, values); }, ramda_1.set(terminDolozeniLens, 'Termín pro doložení dokladu o pojištění je povinný')), ramda_1.when(function () { return zadost && !ramda_1.view(trvaniPristupuLens, values); }, ramda_1.set(trvaniPristupuLens, 'Trvání přístupu je povinné')))({});
    return Object.keys(pipe1).length > 0 ? __assign(__assign({}, pipe1), { dot: '' }) : pipe1;
};
var getDokladLink = function (subjekt, idDoklad) {
    return utils_2.getSubjektLink(subjekt) + "?idDoklad=" + idDoklad;
};
var KontrolaDetailUkoluPage = function (_a) {
    var ukol = _a.ukol, onClose = _a.onClose;
    var history = react_router_dom_1.useHistory();
    var _b = __1.useConfig(), pojisteniUrl = _b.pojisteniUrl, zadostiUrl = _b.zadostiUrl;
    var schvalitPojisteni = __1.usePostJSON(pojisteniUrl + "/Schvaleni");
    var schvalitZadost = __1.usePostJSON(zadostiUrl + "/SchvaleniZadosti");
    var getDokumentPojisteni = __1.useGetFile(pojisteniUrl + "/StazeniDokumentu");
    var getPrilohaZadosti = __1.useGetFile(zadostiUrl + "/StazeniPrilohy");
    var zamitnuti = __1.useBoolean(false);
    var isOpen = __1.useBoolean(true);
    var subjekty = (ukol === null || ukol === void 0 ? void 0 : ukol.subjekt) || [];
    var pojisteni = ukol === null || ukol === void 0 ? void 0 : ukol.pojisteni;
    var zadost = ukol === null || ukol === void 0 ? void 0 : ukol.zadost;
    var loading = __1.useBoolean(false);
    var _c = react_1.useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var handleClose = function (dokonceno) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (onClose) {
                onClose(dokonceno);
            }
            isOpen.setFalse();
            return [2 /*return*/];
        });
    }); };
    var handleSchvaleniUkolu = function (schvaleno, values) { return __awaiter(void 0, void 0, void 0, function () {
        var request, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!((ukol === null || ukol === void 0 ? void 0 : ukol.zdroj.id) === __1.ZdrojUdalosti.EvidencePojisteni)) return [3 /*break*/, 2];
                    request = {
                        stav: {
                            id: schvaleno ? __1.StavPojisteni.Schvaleno : __1.StavPojisteni.Zamitnuto,
                        },
                        idDokument: pojisteni === null || pojisteni === void 0 ? void 0 : pojisteni.idDokument,
                        idDoklad: pojisteni === null || pojisteni === void 0 ? void 0 : pojisteni.idDoklad,
                        duvodZamitnuti: values.duvodZamitnuti,
                        terminDolozeni: values.terminDolozeni,
                    };
                    return [4 /*yield*/, schvalitPojisteni(request)];
                case 1:
                    response = _a.sent();
                    _a.label = 2;
                case 2:
                    if (!((ukol === null || ukol === void 0 ? void 0 : ukol.zdroj.id) === __1.ZdrojUdalosti.EvidenceZadosti)) return [3 /*break*/, 4];
                    request = {
                        idZadost: zadost === null || zadost === void 0 ? void 0 : zadost.idZadost,
                        idZadostSubjekt: zadost === null || zadost === void 0 ? void 0 : zadost.idZadostSubjekt,
                        schvaleno: schvaleno,
                        duvodZamitnuti: values.duvodZamitnuti,
                        trvaniPristupu: values.trvaniPristupu,
                    };
                    return [4 /*yield*/, schvalitZadost(request)];
                case 3:
                    response = _a.sent();
                    _a.label = 4;
                case 4:
                    if (__1.isRight(response))
                        handleClose(true);
                    else
                        setErrorMessage(response.value.message);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleStahnoutPojisteni = function (odkaz) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setErrorMessage(undefined);
                    loading.setTrue();
                    return [4 /*yield*/, getDokumentPojisteni({ idZnalec: subjekty[0].idSubjekt, odkaz: odkaz })];
                case 1:
                    response = _a.sent();
                    if (!__1.isRight(response)) return [3 /*break*/, 3];
                    if (response.value.status === 204) {
                        setErrorMessage('Dokumenty starší více než 2 roky jsou odstraněny.');
                        loading.setFalse();
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, __1.downloadFile(response.value, "" + odkaz, true)];
                case 2:
                    error = _a.sent();
                    if (error) {
                        setErrorMessage(error);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    setErrorMessage('Stahování selhalo.');
                    _a.label = 4;
                case 4:
                    loading.setFalse();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleStahnoutPrilohu = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setErrorMessage(undefined);
                    loading.setTrue();
                    return [4 /*yield*/, getPrilohaZadosti({ idZadostSubjekt: zadost === null || zadost === void 0 ? void 0 : zadost.idZadostSubjekt })];
                case 1:
                    response = _a.sent();
                    if (!__1.isRight(response)) return [3 /*break*/, 3];
                    return [4 /*yield*/, __1.downloadFile(response.value, "" + (zadost === null || zadost === void 0 ? void 0 : zadost.prilohaNazev))];
                case 2:
                    error = _a.sent();
                    if (error) {
                        setErrorMessage(error);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    setErrorMessage('Stahování selhalo.');
                    _a.label = 4;
                case 4:
                    loading.setFalse();
                    return [2 /*return*/];
            }
        });
    }); };
    var defaultTrvaniPristupu = function () {
        var datumTrvani = new Date();
        datumTrvani.setDate(datumTrvani.getDate() + 14);
        return datumTrvani;
    };
    var getRozsahy = function (rozsah) {
        var _a;
        var rozsahy = (_a = rozsah === null || rozsah === void 0 ? void 0 : rozsah.split(';')) === null || _a === void 0 ? void 0 : _a.filter(function (r) { return r === null || r === void 0 ? void 0 : r.length; });
        if (rozsahy === null || rozsahy === void 0 ? void 0 : rozsahy.length)
            return rozsahy.map(function (r) { return react_1.default.createElement("div", null, r); });
        return react_1.default.createElement("div", null, "...");
    };
    return (react_1.default.createElement(utils_2.StyledPopup, { open: isOpen.value, onClose: function () { return handleClose(); }, onClickClose: function () { return isOpen.setFalse(); } }, ukol && (react_1.default.createElement(__1.Form, { initialValues: zadost ? { trvaniPristupu: defaultTrvaniPristupu() } : {}, validate: function (vals) { return validation(vals, zamitnuti.value, !!pojisteni, !!zadost); }, onSubmit: function (val) { return handleSchvaleniUkolu(!zamitnuti.value, val); } }, function (_a) {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var isSubmitting = _a.isSubmitting;
        return (react_1.default.createElement(utils_2.DetailBody, null,
            react_1.default.createElement(utils_2.StyledHeader, null, getTypNazev(ukol.typ)),
            errorMessage && (react_1.default.createElement(__1.Message, { type: "error", onClose: function () { return setErrorMessage(undefined); } }, errorMessage)),
            react_1.default.createElement(utils_2.StyledUdalostListItem, null,
                react_1.default.createElement(utils_2.StyledLabel, { type: "info" }, "Ke kontrole"),
                react_1.default.createElement("div", null, utils_2.getStavUkolu(ukol.stav))),
            zadost && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Identifikace \u017E\u00E1daj\u00EDc\u00EDho"),
                    react_1.default.createElement("div", null, zadost.zadajici)),
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Kontaktn\u00ED e-mail"),
                    react_1.default.createElement("div", null, ((_b = ukol.udaje[0]) === null || _b === void 0 ? void 0 : _b.novaHodnota) || '...')))),
            react_1.default.createElement(utils_2.SubjektBody, null,
                react_1.default.createElement(utils_2.StyledSpan, null, "Subjekt"),
                react_1.default.createElement("div", null, subjekty === null || subjekty === void 0 ? void 0 : subjekty.map(function (s) { return (react_1.default.createElement(utils_2.StyledDiv, { key: "ukol-subjekt-" + s.id },
                    react_1.default.createElement(__1.LinkButton, { onClick: function () { return history.push(utils_2.getSubjektLink(s)); } }, utils_2.getJmenoNazevSubjektu(s)),
                    react_1.default.createElement("div", null, s.druhOsoba))); }))),
            zadost && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "\u010C\u00EDslo \u017E\u00E1dosti"),
                    react_1.default.createElement(utils_2.StyledDiv, null, zadost.idZadost)),
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Rozsah \u017E\u00E1dosti"),
                    react_1.default.createElement(utils_2.StyledDiv, null,
                        react_1.default.createElement("div", null,
                            react_1.default.createElement("strong", null, "Seznamy"),
                            getRozsahy(zadost.rozsahPristupuSeznamy)),
                        react_1.default.createElement("div", null,
                            react_1.default.createElement("strong", null, "Evidence"),
                            getRozsahy(zadost.rozsahPristupuEvidence)))),
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Zd\u016Fvodn\u011Bn\u00ED \u017E\u00E1dosti"),
                    react_1.default.createElement(utils_2.StyledDiv, null, zadost.zduvodneni || '...')),
                (zadost.obdobiOd || zadost.obdobiDo) && (react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "\u00DAdaje v rozsahu"),
                    react_1.default.createElement(utils_2.StyledDiv, null,
                        react_1.default.createElement("b", null, "od"),
                        " ",
                        zadost.obdobiOd ? __1.formatDate(zadost.obdobiOd) : 'vzniku oprávnění',
                        " ",
                        react_1.default.createElement("b", null, "do"),
                        " ",
                        zadost.obdobiDo ? __1.formatDate(zadost.obdobiDo) : 'současnosti'))),
                !zadost.obdobiOd && !zadost.obdobiDo && (react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "\u00DAdaje v rozsahu"),
                    react_1.default.createElement(utils_2.StyledDiv, null, "bez \u010Dasov\u00E9ho omezen\u00ED"))),
                zadost.stavZadosti.id !== __1.StavZadosti.Zamitnuto && (react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Trv\u00E1n\u00ED p\u0159\u00EDstupu do"),
                    react_1.default.createElement(utils_2.DateDiv, null, ((_c = ukol.stav) === null || _c === void 0 ? void 0 : _c.id) === __1.StavUkolu.Dokonceno ? ((_d = __1.formatDate(zadost.trvaniPristupu)) !== null && _d !== void 0 ? _d : '...') : (react_1.default.createElement(__1.DateField, { name: "trvaniPristupu", size: "md", clearIcon: null, minDate: new Date(new Date(Date.now()).setHours(0, 0, 0, 0)) }))))),
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "P\u0159\u00EDloha"),
                    react_1.default.createElement("div", null, (zadost === null || zadost === void 0 ? void 0 : zadost.prilohaNazev) ? (react_1.default.createElement(__1.LinkButton, { type: "button", onClick: function () { return handleStahnoutPrilohu(); }, disabled: loading.value }, zadost === null || zadost === void 0 ? void 0 :
                        zadost.prilohaNazev,
                        " ",
                        loading.value && react_1.default.createElement(icons_1.LoadingOutlined, { spin: true }))) : ('...'))))),
            pojisteni && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Term\u00EDn pro dolo\u017Een\u00ED dokladu o poji\u0161t\u011Bn\u00ED"),
                    react_1.default.createElement(utils_2.DateDiv, null, ((_e = ukol.stav) === null || _e === void 0 ? void 0 : _e.id) === __1.StavUkolu.Dokonceno ? (__1.formatDate(pojisteni.terminDolozeni)) : (react_1.default.createElement(__1.DateField, { name: "terminDolozeni", size: "md", clearIcon: null, minDate: new Date('2000-01-01'), defaultActiveStartDate: new Date(new Date(Date.now()).getFullYear() + 1 + "-12-31") })))),
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Datum nahr\u00E1n\u00ED"),
                    react_1.default.createElement(utils_2.StyledDiv, null, __1.formatDate(pojisteni.datumNahrani))),
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Dokument poji\u0161t\u011Bn\u00ED"),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement(__1.LinkButton, { type: "button", onClick: function () { return handleStahnoutPojisteni(pojisteni.dokumentOdkaz); }, disabled: loading.value },
                            "St\u00E1hnout ",
                            loading.value && react_1.default.createElement(icons_1.LoadingOutlined, { spin: true })))),
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Pozn\u00E1mka"),
                    react_1.default.createElement(utils_2.StyledDiv, null, pojisteni.poznamka)),
                pojisteni.idDokumentZamitnuty && (react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Detail zam\u00EDtnut\u00E9ho dokumentu:"),
                    react_1.default.createElement(__1.LinkButton, { onClick: function () { return history.push(getDokladLink(subjekty[0], pojisteni.idDoklad)); } }, "Zobrazit"))))),
            ((_f = ukol.stav) === null || _f === void 0 ? void 0 : _f.id) === __1.StavUkolu.Dokonceno && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "Vy\u0159\u00EDdil"),
                    react_1.default.createElement(utils_2.StyledDiv, null,
                        react_1.default.createElement("div", null, utils_1.getJmenoNazevAutora(ukol.userVyrizeni)),
                        react_1.default.createElement("div", null, __1.formatDateTime(ukol.datumVyrizeni || null)))),
                react_1.default.createElement(utils_2.SubjektBody, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "V\u00FDsledek"),
                    pojisteni && react_1.default.createElement(utils_2.StyledLink, null, stavPojisteni(pojisteni.stav)),
                    zadost && react_1.default.createElement(utils_2.StyledLink, null, stavZadosti(zadost.stavZadosti))),
                (((_g = pojisteni === null || pojisteni === void 0 ? void 0 : pojisteni.stav) === null || _g === void 0 ? void 0 : _g.id) === __1.StavPojisteni.Zamitnuto || ((_h = zadost === null || zadost === void 0 ? void 0 : zadost.stavZadosti) === null || _h === void 0 ? void 0 : _h.id) === __1.StavZadosti.Zamitnuto) && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(utils_2.SubjektBody, null,
                        react_1.default.createElement(utils_2.StyledSpan, null, "D\u016Fvod zam\u00EDtnut\u00ED"),
                        react_1.default.createElement(utils_2.StyledDiv, null, (_j = pojisteni === null || pojisteni === void 0 ? void 0 : pojisteni.duvodZamitnuti) !== null && _j !== void 0 ? _j : zadost === null || zadost === void 0 ? void 0 : zadost.poznamkaStav)))))),
            react_1.default.createElement(ButtonsDiv, null, ((_k = ukol.stav) === null || _k === void 0 ? void 0 : _k.id) === __1.StavUkolu.Nedokonceno ? (react_1.default.createElement(SubmitDiv, null, zamitnuti.value ? (react_1.default.createElement(ZaitnutiBody, null,
                react_1.default.createElement(ZaitnutiHeader, null,
                    react_1.default.createElement(utils_2.StyledSpan, null, "D\u016Fvod zam\u00EDtnut\u00ED"),
                    react_1.default.createElement(__1.LinkButton, { type: "button", onClick: function () { return zamitnuti.setFalse(); } }, "Zru\u0161it zam\u00EDtnut\u00ED")),
                react_1.default.createElement(__1.TextAreaField, { name: "duvodZamitnuti", disableLabel: true, maxLength: 4000, toolTip: "D\u016Fvod zam\u00EDtnut\u00ED" }),
                react_1.default.createElement(utils_2.ButtonWrapper, null,
                    react_1.default.createElement(__1.Button, { name: "zamitnuto", type: "submit", warning: true, loading: isSubmitting }, "Zam\u00EDtnout")))) : (react_1.default.createElement(utils_2.ButtonWrapper, null,
                react_1.default.createElement(__1.Button, { name: "zamitnuto", type: "button", warning: true, onClick: function () { return zamitnuti.setTrue(); } }, "Zam\u00EDtnout"),
                react_1.default.createElement(GreenButton, { name: "schvaleno", type: "submit", primary: true, loading: isSubmitting }, "Schv\u00E1lit"))))) : (react_1.default.createElement(utils_2.ButtonWrapper, null,
                react_1.default.createElement(__1.Button, { name: "zavrit", type: "button", primary: true, onClick: function () { return handleClose(); } }, "Zav\u0159\u00EDt"))))));
    }))));
};
KontrolaDetailUkoluPage.defaultProps = {
    ukol: undefined,
    onClose: undefined,
};
exports.default = KontrolaDetailUkoluPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
