"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Space = exports.UdalostListItems = exports.UdalostItemsBody = exports.UdalostiBody = exports.UdalostListItem = exports.ListItems = exports.ListItem = void 0;
var styled_components_1 = __importStar(require("styled-components"));
exports.ListItem = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 2fr 3fr;\n  gap: 0px 5px;\n  align-items: flex-start;\n  padding: 0.7em 0;\n  font-size: 15px;\n\n  span:first-child {\n    font-weight: bold;\n  }\n  ", "\n"], ["\n  display: grid;\n  grid-template-columns: 2fr 3fr;\n  gap: 0px 5px;\n  align-items: flex-start;\n  padding: 0.7em 0;\n  font-size: 15px;\n\n  span:first-child {\n    font-weight: bold;\n  }\n  ",
    "\n"])), function (_a) {
    var $breakText = _a.$breakText;
    return $breakText && styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      span:last-child {\n        word-break: break-all;\n      }\n    "], ["\n      span:last-child {\n        word-break: break-all;\n      }\n    "])));
});
exports.ListItems = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 3rem;\n\n  h1 {\n    margin-top: 0;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 24px;\n  }\n"], ["\n  padding: 3rem;\n\n  h1 {\n    margin-top: 0;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 24px;\n  }\n"])));
exports.UdalostListItem = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 3fr;\n  gap: 0px 5px;\n  align-items: center;\n  padding: 0.2em 0;\n\n  span:first-child {\n    font-weight: bold;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 3fr;\n  gap: 0px 5px;\n  align-items: center;\n  padding: 0.2em 0;\n\n  span:first-child {\n    font-weight: bold;\n  }\n"])));
exports.UdalostiBody = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 20px 0;\n"], ["\n  margin: 20px 0;\n"])));
exports.UdalostItemsBody = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 15px;\n  padding: 20px 0;\n  background-color: #f7f9fa;\n"], ["\n  margin-top: 15px;\n  padding: 20px 0;\n  background-color: #f7f9fa;\n"])));
exports.UdalostListItems = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 0 15px;\n  background-color: #f7f9fa;\n"], ["\n  padding: 0 15px;\n  background-color: #f7f9fa;\n"])));
exports.Space = styled_components_1.default.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background-color: #ffffff;\n  height: 2px;\n  width: 100%;\n  margin: 10px 0;\n"], ["\n  background-color: #ffffff;\n  height: 2px;\n  width: 100%;\n  margin: 10px 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
