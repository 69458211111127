"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStavColor = void 0;
var __1 = require("../../../..");
var getStavColor = function (stav) {
    var color;
    if (stav === __1.StavPojisteni.Schvaleno)
        color = '#1CA854';
    if (stav === __1.StavPojisteni.Zamitnuto)
        color = 'red';
    if (stav === __1.StavPojisteni.CekaNaSchvaleni)
        color = '#EDAE0D';
    return color;
};
exports.getStavColor = getStavColor;
