"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var icons_1 = require("@ant-design/icons");
var react_1 = __importStar(require("react"));
var react_router_1 = require("react-router");
var styled_components_1 = __importStar(require("styled-components"));
var __1 = require("../../../..");
var utils_1 = require("../../../../pages/zasobnik/utils");
var PopupChildren = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: auto;\n  max-height: 90vh;\n"], ["\n  overflow: auto;\n  max-height: 90vh;\n"])));
var InfoDiv = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 1em;\n  background-color: #f7f9fa;\n  ", "\n"], ["\n  padding: 1em;\n  background-color: #f7f9fa;\n  ",
    "\n"])), function (_a) {
    var yellowBacground = _a.yellowBacground;
    return yellowBacground && styled_components_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background-color: #edae0d;\n      color: white;\n      padding: 5px 15px;\n    "], ["\n      background-color: #edae0d;\n      color: white;\n      padding: 5px 15px;\n    "])));
});
var Wrapper = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 1em;\n  h2 {\n    font-weight: 700;\n    font-size: 18px;\n    line-height: 21px;\n  }\n"], ["\n  padding: 1em;\n  h2 {\n    font-weight: 700;\n    font-size: 18px;\n    line-height: 21px;\n  }\n"])));
var FlexBox = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 1em;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  margin-top: 1em;\n  align-items: center;\n  justify-content: space-between;\n"])));
var StyledWarning = styled_components_1.default(icons_1.WarningFilled)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #fe505d;\n  margin-right: 1em;\n"], ["\n  color: #fe505d;\n  margin-right: 1em;\n"])));
var TerminSpan = styled_components_1.default.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"])));
var getDokladLink = function (subjekt, idDoklad) {
    return utils_1.getSubjektLink(subjekt) + "?idDoklad=" + idDoklad;
};
var PojisteniInfoPopup = function (udalost) {
    var _a;
    var history = react_router_1.useHistory();
    var zasobnikUrl = __1.useConfig().zasobnikUrl;
    var _b = react_1.useState(true), open = _b[0], setOpen = _b[1];
    var postSetZobrazeno = __1.usePostJSON(zasobnikUrl + "/SetZobrazeno");
    var udalostPojisteni = udalost === null || udalost === void 0 ? void 0 : udalost.udalost;
    var getZbyvajiciDny = function () {
        var now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        var dolozeni = __1.dateStringToDate(udalostPojisteni === null || udalostPojisteni === void 0 ? void 0 : udalostPojisteni.udaje[0].atribut);
        if (!dolozeni)
            return 0;
        var differenceInTime = dolozeni.getTime() - now.getTime();
        var differenceInDays = Math.trunc(differenceInTime / (1000 * 3600 * 24));
        if (differenceInDays < 0) {
            return 0;
        }
        return differenceInDays;
    };
    var handleClose = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            postSetZobrazeno({ IdUdalost: udalostPojisteni.id });
            setOpen(false);
            return [2 /*return*/];
        });
    }); };
    var handleLinkClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            handleClose();
            history.push(getDokladLink(udalostPojisteni.subjekt[0], udalostPojisteni.pojisteni.idDoklad));
            return [2 /*return*/];
        });
    }); };
    return (react_1.default.createElement(__1.Popup, { open: open, onClose: function () { return handleClose(); } },
        react_1.default.createElement(PopupChildren, null, ((_a = udalostPojisteni === null || udalostPojisteni === void 0 ? void 0 : udalostPojisteni.typ) === null || _a === void 0 ? void 0 : _a.id) === __1.TypyUdalosti.NotifikaceZamitnutiPojisteni ? (react_1.default.createElement(Wrapper, null,
            react_1.default.createElement("h2", null,
                react_1.default.createElement(StyledWarning, null),
                "Zam\u00EDtnut\u00ED dokladu o uzav\u0159en\u00E9m profesn\u00EDm poji\u0161t\u011Bn\u00ED"),
            react_1.default.createElement(InfoDiv, null,
                react_1.default.createElement("strong", null, "D\u016Fvod zam\u00EDtnut\u00ED"),
                react_1.default.createElement("br", null),
                react_1.default.createElement("br", null),
                udalostPojisteni.pojisteni.duvodZamitnuti),
            react_1.default.createElement(FlexBox, null,
                react_1.default.createElement(__1.LinkButton, { onClick: function () { return handleLinkClick(); } }, "Zobrazit detail"),
                react_1.default.createElement(TerminSpan, null,
                    "Term\u00EDn pro dolo\u017Een\u00ED: ",
                    __1.formatDate(udalostPojisteni.pojisteni.terminDolozeni))))) : (react_1.default.createElement(Wrapper, null,
            react_1.default.createElement("h2", null,
                react_1.default.createElement(StyledWarning, null),
                "Upozorn\u011Bn\u00ED na bl\u00ED\u017E\u00EDc\u00ED se term\u00EDn dolo\u017Een\u00ED dokladu"),
            react_1.default.createElement(InfoDiv, null, udalostPojisteni === null || udalostPojisteni === void 0 ? void 0 : udalostPojisteni.infoPolozka),
            react_1.default.createElement(FlexBox, null,
                react_1.default.createElement(InfoDiv, { yellowBacground: true },
                    "zb\u00FDv\u00E1 ",
                    getZbyvajiciDny(),
                    " dn\u00ED"),
                react_1.default.createElement(TerminSpan, null,
                    "Term\u00EDn pro dolo\u017Een\u00ED: ",
                    __1.dateStringToString(udalostPojisteni === null || udalostPojisteni === void 0 ? void 0 : udalostPojisteni.udaje[0].atribut))))))));
};
exports.default = PojisteniInfoPopup;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
