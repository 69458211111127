"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInputSizeStyles = void 0;
var styled_components_1 = require("styled-components");
var getWidthFromSize = function (size) {
    switch (size) {
        case 'sm':
            return '25%';
        case 'md':
            return '50%';
        case 'lg':
            return '75%';
        default:
            return '100%';
    }
};
var getInputSizeStyles = function (size) {
    return styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: ", ";\n\n    @media only screen and (max-width: 450px) {\n      width: 100%;\n    }\n  "], ["\n    width: ", ";\n\n    @media only screen and (max-width: 450px) {\n      width: 100%;\n    }\n  "])), getWidthFromSize(size));
};
exports.getInputSizeStyles = getInputSizeStyles;
var templateObject_1;
