"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var formik_1 = require("formik");
var react_1 = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var StyledTextArea = styled_components_1.default.textarea(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 1px solid #686868;\n  border-radius: 4px;\n  font-size: 15px;\n  padding: 14px;\n  resize: none;\n  height: 100px;\n  text-overflow: ellipsis;\n  outline: none;\n  width: 100%;\n  overflow: hidden;\n  transition: 100ms;\n\n  &:focus:not(:read-only) {\n    box-shadow: 0 0 0 1px #2362a2;\n  }\n\n  &:read-only {\n    background-color: #eff3f4;\n  }\n\n  ::placeholder {\n    /* Chrome, Firefox, Opera, Safari 10.1+ */\n    color: #3b3b3b;\n    opacity: 0.5; /* Firefox */\n    line-height: normal;\n  }\n\n  ", "\n\n  ", "\n"], ["\n  border: 1px solid #686868;\n  border-radius: 4px;\n  font-size: 15px;\n  padding: 14px;\n  resize: none;\n  height: 100px;\n  text-overflow: ellipsis;\n  outline: none;\n  width: 100%;\n  overflow: hidden;\n  transition: 100ms;\n\n  &:focus:not(:read-only) {\n    box-shadow: 0 0 0 1px #2362a2;\n  }\n\n  &:read-only {\n    background-color: #eff3f4;\n  }\n\n  ::placeholder {\n    /* Chrome, Firefox, Opera, Safari 10.1+ */\n    color: #3b3b3b;\n    opacity: 0.5; /* Firefox */\n    line-height: normal;\n  }\n\n  ",
    "\n\n  ",
    "\n"])), function (_a) {
    var $hasError = _a.$hasError;
    return $hasError && styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border: 2px solid #ba0d23;\n    "], ["\n      border: 2px solid #ba0d23;\n    "])));
}, function (_a) {
    var $oneLine = _a.$oneLine;
    return $oneLine && styled_components_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      height: 50px;\n    "], ["\n      height: 50px;\n    "])));
});
var TextArea = function (_a) {
    var _b;
    var name = _a.name, placeholder = _a.placeholder, onFocus = _a.onFocus, readOnly = _a.readOnly, onClick = _a.onClick, maxLength = _a.maxLength, oneLine = _a.oneLine;
    var _c = formik_1.useField(name), field = _c[0], meta = _c[1], helpers = _c[2];
    var textRef = react_1.useRef();
    var value = (_b = field.value) !== null && _b !== void 0 ? _b : '';
    if (oneLine) {
        react_1.useEffect(function () {
            if (value.trim().length === 0 && (placeholder === null || placeholder === void 0 ? void 0 : placeholder.length)) {
                textRef.current.style.height = 'auto';
            }
            else if (value.trim().length < 50) {
                textRef.current.style.height = '50px';
            }
            else {
                textRef.current.style.height = textRef.current.scrollHeight + "px";
            }
        }, [value]);
    }
    var handleChange = function (e) {
        helpers.setValue(e.target.value);
    };
    return (react_1.default.createElement(StyledTextArea, __assign({}, field, { ref: textRef, placeholder: placeholder, onFocus: onFocus, onClick: onClick, value: field.value || '', name: name, id: name, autoComplete: "off", readOnly: readOnly, "$hasError": !!meta.error, onChange: handleChange, maxLength: maxLength, "$oneLine": oneLine, onBlur: handleChange })));
};
TextArea.defaultProps = {
    onFocus: undefined,
    onClick: undefined,
    placeholder: undefined,
    readOnly: false,
    maxLength: undefined,
    oneLine: false,
};
exports.default = TextArea;
var templateObject_1, templateObject_2, templateObject_3;
