"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tlumocnikValuesForROS = exports.znalecValuesForROS = void 0;
var __1 = require("../../..");
var znalecValuesForROS = function (znalecValues, values) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var pravniFormaKod;
    if (znalecValues.znalec.adresa) {
        pravniFormaKod = __1.PravniFormaKod.FytickaOsoba;
    }
    if (znalecValues.znalec.adresaZahranicni && ((_a = znalecValues.znalec.soudniZnalec) === null || _a === void 0 ? void 0 : _a.pobyt)) {
        pravniFormaKod = __1.PravniFormaKod.Zahranicni;
    }
    var pozastaveniPreruseni = false;
    if (values.pozastaveniOpravneniBool) {
        pozastaveniPreruseni = values.pozastaveniOpravneniBool;
    }
    else if (values.pozastaveniOpravneni) {
        var dateNow_1 = Date.parse(new Date(Date.now()).toDateString());
        values.pozastaveniOpravneni.forEach(function (pozastaveni) {
            if (pozastaveni.datumOd != null && (pozastaveni.datumDo === null || Date.parse(pozastaveni.datumDo) >= dateNow_1) && dateNow_1 >= Date.parse(pozastaveni.datumOd)) {
                pozastaveniPreruseni = true;
            }
        });
    }
    return {
        subjektId: znalecValues.znalec.id,
        uzivatel: values.uzivatel,
        SystemId: 'Znalci',
        ICO: znalecValues.znalec.ico,
        NazevOsoby: znalecValues.znalec.nazev,
        PravniFormaKod: pravniFormaKod,
        DatumVznikuOpravneni: values.datumVznikuOpravneni,
        DatumZanikuOpravneni: values.datumZanikuOpravneni,
        DatumZanikuPlatnost: values.datumZanikuPlatnost,
        PozastaveniPreruseni: pozastaveniPreruseni,
        AdresaSidla: (_b = znalecValues.znalec.adresa) === null || _b === void 0 ? void 0 : _b.adresaText,
        AdresaSidlaKod: (_c = znalecValues.znalec.adresa) === null || _c === void 0 ? void 0 : _c.ruian,
        AIFO: (_d = znalecValues.znalec.soudniZnalec) === null || _d === void 0 ? void 0 : _d.aifo,
        osoba: {
            FyzickaOsoba: ((_e = znalecValues.znalec.soudniZnalec) === null || _e === void 0 ? void 0 : _e.jmeno) + " " + ((_f = znalecValues.znalec.soudniZnalec) === null || _f === void 0 ? void 0 : _f.prijmeni),
            Adresa: (_h = (_g = znalecValues.znalec.soudniZnalec) === null || _g === void 0 ? void 0 : _g.pobyt) === null || _h === void 0 ? void 0 : _h.adresaText,
            AdresaKod: (_k = (_j = znalecValues.znalec.soudniZnalec) === null || _j === void 0 ? void 0 : _j.pobyt) === null || _k === void 0 ? void 0 : _k.ruian,
        },
    };
};
exports.znalecValuesForROS = znalecValuesForROS;
var tlumocnikValuesForROS = function (tlumocnikValues, values) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var pravniFormaKod;
    if (tlumocnikValues.tlumocnik.adresa) {
        pravniFormaKod = __1.PravniFormaKod.FytickaOsoba;
    }
    if (tlumocnikValues.tlumocnik.adresaZahranicni && tlumocnikValues.tlumocnik.pobyt) {
        pravniFormaKod = __1.PravniFormaKod.Zahranicni;
    }
    var pozastaveniPreruseni = false;
    if (values.pozastaveniOpravneniBool) {
        pozastaveniPreruseni = values.pozastaveniOpravneniBool;
    }
    else if (values.pozastaveniOpravneni) {
        var dateNow_2 = Date.parse(new Date(Date.now()).toDateString());
        values.pozastaveniOpravneni.forEach(function (pozastaveni) {
            if (pozastaveni.datumOd != null && (pozastaveni.datumDo === null || Date.parse(pozastaveni.datumDo) >= dateNow_2) && dateNow_2 >= Date.parse(pozastaveni.datumOd)) {
                pozastaveniPreruseni = true;
            }
        });
    }
    return {
        subjektId: tlumocnikValues.tlumocnik.id,
        uzivatel: values.uzivatel,
        SystemId: 'Tlumocnici',
        ICO: tlumocnikValues.tlumocnik.ico,
        PravniFormaKod: pravniFormaKod,
        DatumVznikuOpravneni: values.datumVznikuOpravneni,
        DatumZanikuOpravneni: values.datumZanikuOpravneni,
        DatumZanikuPlatnost: values.datumZanikuPlatnost,
        PozastaveniPreruseni: pozastaveniPreruseni,
        AdresaSidla: (_a = tlumocnikValues.tlumocnik.adresa) === null || _a === void 0 ? void 0 : _a.adresaText,
        AdresaSidlaKod: (_b = tlumocnikValues.tlumocnik.adresa) === null || _b === void 0 ? void 0 : _b.ruian,
        AIFO: (_c = tlumocnikValues.tlumocnik) === null || _c === void 0 ? void 0 : _c.aifo,
        osoba: {
            FyzickaOsoba: ((_d = tlumocnikValues.tlumocnik) === null || _d === void 0 ? void 0 : _d.jmeno) + " " + ((_e = tlumocnikValues.tlumocnik) === null || _e === void 0 ? void 0 : _e.prijmeni),
            Adresa: (_g = (_f = tlumocnikValues.tlumocnik) === null || _f === void 0 ? void 0 : _f.pobyt) === null || _g === void 0 ? void 0 : _g.adresaText,
            AdresaKod: (_j = (_h = tlumocnikValues.tlumocnik) === null || _h === void 0 ? void 0 : _h.pobyt) === null || _j === void 0 ? void 0 : _j.ruian,
        },
    };
};
exports.tlumocnikValuesForROS = tlumocnikValuesForROS;
