"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClickableLink = exports.UriKontext = exports.isInRole = exports.NormalizeString = exports.TypVypisu = exports.downloadFile = exports.join = exports.map = exports.dateStringToString = exports.dateStringToDate = exports.formatDateTime = exports.formatDate = exports.getNameFromTlumocnik = exports.getNameFromZnalec = exports.getJmenoPrijmeni = exports.removeTrailingSlash = void 0;
var removeTrailingSlash = function (str) { return str.replace(/\/+$/, ''); };
exports.removeTrailingSlash = removeTrailingSlash;
var getJmenoPrijmeni = function (jmeno, prijmeni, titul, titulZa) {
    var name = [titul, jmeno, prijmeni].filter(function (e) { return e; }).join(' ');
    if (titulZa) {
        return name + ", " + titulZa;
    }
    return name;
};
exports.getJmenoPrijmeni = getJmenoPrijmeni;
var getNameFromZnalec = function (znalec) {
    if (znalec.soudniZnalec) {
        return exports.getJmenoPrijmeni(znalec.soudniZnalec.jmeno, znalec.soudniZnalec.prijmeni, znalec.soudniZnalec.titul, znalec.soudniZnalec.titulZa);
    }
    var nazev = znalec.nazev;
    if (znalec.nazevUtvaru)
        nazev = nazev + " - " + znalec.nazevUtvaru;
    return nazev || '';
};
exports.getNameFromZnalec = getNameFromZnalec;
var getNameFromTlumocnik = function (tlumocnik) {
    if (tlumocnik) {
        return exports.getJmenoPrijmeni(tlumocnik.jmeno, tlumocnik.prijmeni, tlumocnik.titul, tlumocnik.titulZa);
    }
    return '';
};
exports.getNameFromTlumocnik = getNameFromTlumocnik;
var formatDate = function (date) {
    if (date && date.length > 4) {
        var tmp = new Date(date);
        return tmp.getDate() + ". " + (tmp.getMonth() + 1) + ". " + tmp.getFullYear();
    }
    return null;
};
exports.formatDate = formatDate;
var formatDateTime = function (date) {
    if (date && date.length > 4) {
        var tmp = new Date(date);
        return tmp.getDate() + ". " + (tmp.getMonth() + 1) + ". " + tmp.getFullYear() + " " + tmp.toLocaleTimeString('cs-CZ');
    }
    return null;
};
exports.formatDateTime = formatDateTime;
var dateStringToDate = function (stringDate) {
    var dateParts = stringDate === null || stringDate === void 0 ? void 0 : stringDate.split('.');
    if (dateParts && dateParts.length === 3) {
        return new Date(dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0]);
    }
    return null;
};
exports.dateStringToDate = dateStringToDate;
var dateStringToString = function (stringDate) {
    if (stringDate) {
        var date = exports.dateStringToDate(stringDate);
        if (date)
            return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getFullYear();
        return null;
    }
    return null;
};
exports.dateStringToString = dateStringToString;
var map = function (fc, arr) { return (arr || []).map(fc); };
exports.map = map;
var join = function (separator, arr) { return (arr || []).join(separator); };
exports.join = join;
var downloadFile = function (result, defaultName, newTab) {
    if (newTab === void 0) { newTab = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var fileName, blob, newBlob, blobUrl, link, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    if (!result) {
                        return [2 /*return*/];
                    }
                    fileName = defaultName;
                    if (result.headers.get('content-disposition')) {
                        if (result.headers.get('content-disposition').split('UTF-8')[1]) {
                            // eslint-disable-next-line prefer-destructuring
                            fileName = decodeURIComponent(result.headers.get('content-disposition').split('UTF-8')[1].replace(/['"]+/g, ''));
                        }
                    }
                    return [4 /*yield*/, result.blob()];
                case 1:
                    blob = _b.sent();
                    newBlob = new Blob([blob], { type: blob.type });
                    blobUrl = window.URL.createObjectURL(newBlob);
                    if (newBlob.type === 'application/pdf' && newTab) {
                        window.open(blobUrl, '_blank');
                    }
                    else {
                        link = document.createElement('a');
                        link.href = blobUrl;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        if (link.parentNode) {
                            link.parentNode.removeChild(link);
                        }
                    }
                    window.URL.revokeObjectURL(blob);
                    return [2 /*return*/];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, 'Stahování souboru selhalo. Zkuste akci opakovat později.'];
                case 3: return [2 /*return*/];
            }
        });
    });
};
exports.downloadFile = downloadFile;
var TypVypisu;
(function (TypVypisu) {
    TypVypisu[TypVypisu["Inteni"] = 0] = "Inteni";
    TypVypisu[TypVypisu["Public"] = 1] = "Public";
    TypVypisu[TypVypisu["Pecet"] = 2] = "Pecet";
    TypVypisu[TypVypisu["Negativni"] = 3] = "Negativni";
    TypVypisu[TypVypisu["Eng"] = 4] = "Eng";
})(TypVypisu = exports.TypVypisu || (exports.TypVypisu = {}));
var NormalizeString = function (s) {
    if (s) {
        return s
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .replace(/\s/g, '');
    }
    return '';
};
exports.NormalizeString = NormalizeString;
var isInRole = function (obsahuje, role) {
    var constains = false;
    obsahuje.forEach(function (roli) {
        if (role === null || role === void 0 ? void 0 : role.includes(roli)) {
            constains = true;
        }
    });
    return constains;
};
exports.isInRole = isInRole;
var UriKontext = function () {
    var kontext = '';
    if (window.location.href.includes('/private/')) {
        kontext = '/private';
    }
    return kontext;
};
exports.UriKontext = UriKontext;
var getClickableLink = function (link) {
    return link.startsWith('http:') || link.startsWith('https:') ? link : "http://" + link;
};
exports.getClickableLink = getClickableLink;
