/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';

import { CacheProvider, ConfigProvider, GlobalStyle } from 'common';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import App from './App';
import reportWebVitals from './reportWebVitals';
/* eslint-enable */

ReactDOM.render(
  <React.StrictMode>
    <CacheProvider>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <GlobalStyle />
          <ConfigProvider typ="common">
            <App />
          </ConfigProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </CacheProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
