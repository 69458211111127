"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var ramda_1 = require("ramda");
var react_1 = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var TabHeader = styled_components_1.default.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n  border-bottom: 2px solid #2362a2;\n  padding: 0;\n  margin: 0;\n"], ["\n  display: flex;\n  align-items: flex-end;\n  border-bottom: 2px solid #2362a2;\n  padding: 0;\n  margin: 0;\n"])));
var TabContent = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: #fff;\n"], ["\n  background: #fff;\n"])));
var TabTitle = styled_components_1.default.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-right: rgb(229, 235, 240) 2px solid;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  text-decoration: underline;\n  font-size: 15px;\n  font-weight: normal;\n  line-height: 26px;\n  letter-spacing: 0.0125em;\n  color: #2362a2;\n  padding: 12px 20px;\n  height: auto;\n  max-height: 76px;\n  background: #e5ebf0;\n  border: none;\n\n  ", "\n"], ["\n  border-right: rgb(229, 235, 240) 2px solid;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  text-decoration: underline;\n  font-size: 15px;\n  font-weight: normal;\n  line-height: 26px;\n  letter-spacing: 0.0125em;\n  color: #2362a2;\n  padding: 12px 20px;\n  height: auto;\n  max-height: 76px;\n  background: #e5ebf0;\n  border: none;\n\n  ",
    "\n"])), function (_a) {
    var isActive = _a.isActive;
    return isActive && styled_components_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      color: #fff;\n      background: #2362a2;\n    "], ["\n      color: #fff;\n      background: #2362a2;\n    "])));
});
var Li = styled_components_1.default.li(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  list-style-type: none;\n"], ["\n  list-style-type: none;\n"])));
var Tabs = function (_a) {
    var activeId = _a.activeId, children = _a.children, onSelect = _a.onSelect;
    sessionStorage.setItem('vyhledavaniTyp', activeId);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(TabHeader, { role: "tablist" }, react_1.Children.map(children, function (component) {
            var id = ramda_1.path(['props', 'id'], component);
            var title = ramda_1.path(['props', 'title'], component);
            return (react_1.default.createElement(Li, { role: "tab" },
                react_1.default.createElement(TabTitle, { isActive: id === activeId, onClick: id ? function () { return onSelect(id); } : undefined }, title)));
        })),
        react_1.default.createElement(TabContent, null, react_1.Children.toArray(children).find(ramda_1.pathEq(['props', 'id'], activeId)))));
};
var Tab = function (_a) {
    var id = _a.id, children = _a.children;
    return react_1.default.createElement("div", { "data-testid": id }, children);
};
Tabs.Tab = Tab;
exports.default = Tabs;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
