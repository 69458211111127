"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addressFieldValidation = exports.zahranicniFieldValidation = void 0;
var ramda_1 = require("ramda");
var constants_1 = require("../../../../constants");
var zahranicniFieldValidation = function () { return function (values) {
    if (!values.adresaText && !values.typAdresy) {
        return {};
    }
    if (!values.adresaText) {
        return { adresaText: 'Zahraniční adresa je povinná.' };
    }
    if (!values.typAdresy) {
        return { typAdresy: 'Typ zahraniční adresy je povinný.' };
    }
    return {};
}; };
exports.zahranicniFieldValidation = zahranicniFieldValidation;
var addressFieldValidation = function (hideTypAdresy, zadaneTypyAdres, isZnalec) { return function (values) {
    var _a;
    var errorMessage = 'Nebyly vyplněny minimální povinné kombinace údajů adres. Zkontrolujte, prosím, zdali jste vyplnili Obec a Číslo popisné nebo Obec, Ulici a Číslo orientační.';
    var requiredKeys = ['obec', 'psc'];
    if (!isZnalec && ramda_1.prop('adresaText', values)) {
        // pokud je tlumocnik/prekladatel, muze vyplnit zahranicni adresu
        return {};
    }
    if (!hideTypAdresy) {
        requiredKeys.unshift('typAdresy');
        errorMessage += ' Nezapomeňte zvolit Typ adresy.';
    }
    // Povinné atributy PSČ, Obec, Typ adresy
    var key = requiredKeys.find(function (requiredKey) { return !ramda_1.prop(requiredKey, values); });
    if (key) {
        return _a = {}, _a[key] = errorMessage, _a;
    }
    // Pokud není vyplněn atribut Ulice, potom musí být vyplněno Číslo popisné.
    if (!values.ulice && !(values.cisloPopisne || values.cisloEvidencni)) {
        return { cisloPopisne: errorMessage };
    }
    // Pokud je vyplněn atribut Ulice, potom musí být vyplněno Číslo orientační.
    if (values.ulice && !values.cisloOrientacni && !(values.cisloPopisne || values.cisloEvidencni)) {
        return { cisloOrientacni: errorMessage };
    }
    // Atribut PSČ musí obsahovat 5 číslic.
    if (values.psc && !ramda_1.test(new RegExp('^.{5}$'), values.psc.toString())) {
        return { psc: 'Atribut PSČ musí obsahovat 5 znaků.' };
    }
    var obsahujeTypAdresy = function (typ) {
        return zadaneTypyAdres === null || zadaneTypyAdres === void 0 ? void 0 : zadaneTypyAdres.includes(typ);
    };
    var adresaTrvalehoPobytuNaUzemiCR = isZnalec ? constants_1.ZnalciTypAdresy.adresaTrvalehoPobytuNaUzemiCR : constants_1.TlumocniciTypAdresy.adresaTrvalehoPobytuNaUzemiCR;
    var adresaPobytuNaUzemiCR = isZnalec ? constants_1.ZnalciTypAdresy.adresaPobytuNaUzemiCR : constants_1.TlumocniciTypAdresy.adresaPobytuNaUzemiCR;
    var kontaktniAdresa = isZnalec ? constants_1.ZnalciTypAdresy.kontaktniAdresa : constants_1.TlumocniciTypAdresy.kontaktniAdresa;
    var adresaPobytuMimoUzemiCR = isZnalec ? constants_1.ZnalciTypAdresy.adresaPobytuMimoUzemiCR : constants_1.TlumocniciTypAdresy.adresaPobytuMimoUzemiCR;
    var adresaTrvalehoPobytuMimoUzemiCR = isZnalec ? constants_1.ZnalciTypAdresy.adresaTrvalehoPobytuMimoUzemiCR : constants_1.TlumocniciTypAdresy.adresaTrvalehoPobytuMimoUzemiCR;
    if (obsahujeTypAdresy(adresaTrvalehoPobytuNaUzemiCR) || obsahujeTypAdresy(adresaPobytuNaUzemiCR)) {
        if (values.typAdresy === kontaktniAdresa) {
            return { typAdresy: 'Nelze vložit adresu typu Kontaktní adresa na území ČR, protože je u osoby evidována adresa pobytu na území ČR.' };
        }
    }
    if (obsahujeTypAdresy(kontaktniAdresa)) {
        if (values.typAdresy === adresaTrvalehoPobytuNaUzemiCR || values.typAdresy === adresaPobytuNaUzemiCR) {
            return { typAdresy: 'Nelze vložit adresu trvalého pobytu či prostého pobytu na území ČR, protože je u osoby evidována kontaktní adresa na území ČR.' };
        }
    }
    if (obsahujeTypAdresy(adresaPobytuMimoUzemiCR) || obsahujeTypAdresy(adresaTrvalehoPobytuMimoUzemiCR)) {
        if (values.typAdresy === adresaTrvalehoPobytuNaUzemiCR) {
            return { typAdresy: 'Nelze vložit adresu trvalého pobytu na území ČR, protože je u osoby evidována adresa pobytu v zahraničí.' };
        }
    }
    return {};
}; };
exports.addressFieldValidation = addressFieldValidation;
