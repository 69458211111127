"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAddDisabled = exports.getNadrazeneIds = exports.getTreeIds = exports.getListIds = exports.getSpecializaceById = exports.getJSpecializaceById = exports.getPodrizene = exports.filterAvailable = exports.viewPodrizene = exports.findByIdSpecializace = exports.mapSpecializaceToOptions = exports.mapJSpecializaceToOptions = exports.podrizeneLens = exports.podrizeneSpeciaizaceProp = exports.idSpecializaceProp = void 0;
var ramda_1 = require("ramda");
exports.idSpecializaceProp = ramda_1.prop('idSpecializace');
exports.podrizeneSpeciaizaceProp = ramda_1.prop('podrizene');
exports.podrizeneLens = ramda_1.lensProp('podrizene');
var mapJSpecializaceToOptions = function (specializace) { return specializace.map(function (s) { return ({ label: s.nazev, value: s.id }); }); };
exports.mapJSpecializaceToOptions = mapJSpecializaceToOptions;
var mapSpecializaceToOptions = function (specializace) { return specializace.map(function (s) { return ({ label: s.nazev, value: s.idSpecializace, data: s }); }); };
exports.mapSpecializaceToOptions = mapSpecializaceToOptions;
var findByIdSpecializace = function (idSpecializace, specializace) { return ramda_1.find(ramda_1.propEq('idSpecializace', idSpecializace), specializace); };
exports.findByIdSpecializace = findByIdSpecializace;
var viewPodrizene = function (specializace) { return ramda_1.view(exports.podrizeneLens, specializace) || []; };
exports.viewPodrizene = viewPodrizene;
/**
 * Vrati specializace neobsazene ve vyberu
 * @param selectedIds
 */
var filterAvailable = function (selectedIds) {
    return ramda_1.filter(function (specializace) { return !ramda_1.includes(specializace.idSpecializace, selectedIds); });
};
exports.filterAvailable = filterAvailable;
/**
 * Vraci seznam podrizenych specializaci
 * @param idSpecializace
 */
var getPodrizene = function (idSpecializace) { return function (specializace) {
    var found = exports.findByIdSpecializace(idSpecializace, specializace);
    if (found) {
        return found.podrizene;
    }
    return [];
}; };
exports.getPodrizene = getPodrizene;
var getJSpecializaceById = function (id, allSpecializace) {
    return allSpecializace.find(function (s) { return s.id === id; });
};
exports.getJSpecializaceById = getJSpecializaceById;
/**
 * Hleda specializaci ve strome podle jejiho ID
 */
var getSpecializaceById = function (id, allSpecializace) {
    return allSpecializace.reduce(function (acc, specializace) {
        if (specializace.idSpecializace === id) {
            return specializace;
        }
        var podrizene = ramda_1.view(exports.podrizeneLens, specializace);
        if (podrizene && podrizene.length) {
            var found = exports.getSpecializaceById(id, podrizene);
            if (found) {
                return found;
            }
        }
        return acc;
    }, undefined);
};
exports.getSpecializaceById = getSpecializaceById;
/**
 * Vraci ID vsech vybranych specializaci
 * @param value
 */
var getListIds = function (value) {
    return ramda_1.reduce(function (idAcc, specializace) {
        var podrizene = exports.podrizeneSpeciaizaceProp(specializace);
        var podrizeneItem = ramda_1.view(ramda_1.lensPath(['podrizene', 0]), specializace);
        if (!ramda_1.isEmpty(podrizeneItem) && !ramda_1.isNil(podrizeneItem)) {
            return __spreadArrays(idAcc, exports.getListIds(podrizene));
        }
        var idSpecializace = exports.idSpecializaceProp(specializace);
        return idSpecializace ? ramda_1.append(idSpecializace, idAcc) : idAcc;
    }, [])(value || []);
};
exports.getListIds = getListIds;
/**
 * Vraci ID vybranych specializaci vcetne jejich rodicu
 * @param value
 */
var getTreeIds = function (value) {
    return ramda_1.reduce(function (idAcc, specializace) {
        var podrizene = exports.podrizeneSpeciaizaceProp(specializace);
        var podrizeneItem = ramda_1.view(ramda_1.lensPath(['podrizene', 0]), specializace);
        if (!ramda_1.isEmpty(podrizeneItem) && !ramda_1.isNil(podrizeneItem)) {
            return __spreadArrays(idAcc, [exports.idSpecializaceProp(specializace)], exports.getTreeIds(podrizene));
        }
        var idSpecializace = exports.idSpecializaceProp(specializace);
        return idSpecializace ? ramda_1.append(idSpecializace, idAcc) : idAcc;
    }, [])(value || []);
};
exports.getTreeIds = getTreeIds;
/**
 * Vraci ID specializace včetně nadřazených
 * @param idSpecializace
 * @param allSpecializace
 */
var getNadrazeneIds = function (idSpecializace, allSpecializace) {
    var _a;
    var idNadrazene = (_a = exports.getSpecializaceById(idSpecializace, allSpecializace)) === null || _a === void 0 ? void 0 : _a.idNadrazene;
    if (!idNadrazene)
        return [idSpecializace];
    return __spreadArrays([idSpecializace], exports.getNadrazeneIds(idNadrazene, allSpecializace));
};
exports.getNadrazeneIds = getNadrazeneIds;
var isDisableAccConfig = function (disableAdd) { return typeof disableAdd === 'object'; };
var isAddDisabled = function (disableAdd, where) {
    if (isDisableAccConfig(disableAdd) && where) {
        return !!disableAdd[where];
    }
    return !!disableAdd;
};
exports.isAddDisabled = isAddDisabled;
