"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledTable = exports.StyledH2 = void 0;
var styled_components_1 = __importStar(require("styled-components"));
exports.StyledH2 = styled_components_1.default.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 400;\n  line-height: 140%;\n  font-size: 28px;\n  color: #000000;\n  ", "\n"], ["\n  font-weight: 400;\n  line-height: 140%;\n  font-size: 28px;\n  color: #000000;\n  ",
    "\n"])), function (_a) {
    var isDetail = _a.isDetail;
    return isDetail && styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      margin: 3rem 0rem 0rem 3rem;\n    "], ["\n      margin: 3rem 0rem 0rem 3rem;\n    "])));
});
exports.StyledTable = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n  table {\n    tHead {\n      background-color: #fff;\n      tr {\n        th {\n          ", "\n          border: none;\n        }\n      }\n    }\n    tBody {\n      tr {\n        td {\n          ", "\n          border-bottom: none;\n          border-top: dashed 1px #d5e1eb;\n        }\n      }\n    }\n  }\n"], ["\n  ",
    "\n  table {\n    tHead {\n      background-color: #fff;\n      tr {\n        th {\n          ",
    "\n          border: none;\n        }\n      }\n    }\n    tBody {\n      tr {\n        td {\n          ",
    "\n          border-bottom: none;\n          border-top: dashed 1px #d5e1eb;\n        }\n      }\n    }\n  }\n"])), function (_a) {
    var isDetail = _a.isDetail;
    return isDetail && styled_components_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin: 1rem 2rem;\n    "], ["\n      margin: 1rem 2rem;\n    "])));
}, function (_a) {
    var isDetail = _a.isDetail;
    return !isDetail && styled_components_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n              padding: 20px 5px;\n            "], ["\n              padding: 20px 5px;\n            "])));
}, function (_a) {
    var isDetail = _a.isDetail;
    return !isDetail && styled_components_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n              padding: 20px 5px;\n            "], ["\n              padding: 20px 5px;\n            "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
