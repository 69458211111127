"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRight = exports.isLeft = exports.Right = exports.Left = void 0;
var Tag;
(function (Tag) {
    Tag["right"] = "right";
    Tag["left"] = "left";
})(Tag || (Tag = {}));
var Left = function (val) { return ({ value: val, tag: Tag.left }); };
exports.Left = Left;
var Right = function (val) { return ({ value: val, tag: Tag.right }); };
exports.Right = Right;
var isLeft = function (val) { return val.tag === Tag.left; };
exports.isLeft = isLeft;
var isRight = function (val) { return val.tag === Tag.right; };
exports.isRight = isRight;
